import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'

const UploadCsvWidget = ({ position }: { position?: string }) => {
  return (
    <article className={`bg-transparent-white border border-gray p-5 ${position}`} >
      <h2 className={"text-2xl font-reg text-almost-black"}>
        Upload Products Via .CSV
      </h2>
      <p className="text-xs font-reg mb-4 text-dark-gray">
        Click here to upload your products via .CSV and start selling your excess inventory
      </p>
      <Link
        to="/dashboard/csv_import"
        className={"flex justify-center border border-gray p-3"}
      >
        <FontAwesomeIcon icon={faUpload} className={`mr-2 h-4 w-4 text-pink`} />
        <p className="text-sm font-reg text-dark-gray">Upload Products Via .CSV</p>
      </Link>
    </article>
  )
}

export default UploadCsvWidget
