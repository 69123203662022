import React from "react"
import { Outlet, useLocation, Link } from 'react-router-dom'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import Logo from "../entrypoints/images/logo240.webp"




const Inquiry = () => {
  const location = useLocation()
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search })
  }, [location])

  const mdScreen = window.matchMedia("(min-width: 768px)")
  const buyerImg = "https://ik.imagekit.io/mmbi/man_on_desktop_comp.png?updatedAt=1736515098776"
  const sellerImg = "https://ik.imagekit.io/mmbi/two_ppl_packaging.png?updatedAt=1736515099114"

  return (
    <section className={'h-screen flex flex-col'}>
      <main className='flex h-full'>
        <section className={'bg-light w-full md:w-3/5 overflow-y-scroll overflow-x-hidden flex flex-col items-center justify-between'}>
          <Outlet />
          <footer className={'w-full flex justify-center pb-4'}>
            <Link to="/">
              <img src={Logo} alt={"made me buy it logo"} className='w-32 m-2'/>
            </Link>
          </footer>
        </section>
        {
          mdScreen.matches &&
          <aside className={'w-2/5 flex justify-center px-6 bg-primary'}>
            <div className={'flex flex-col h-full items-center justify-start'}>
              {
                location.pathname.includes("retailer") ? <img src={buyerImg} className={'mt-12'} /> : <img src={sellerImg} className={'mt-12'} />
              }
            </div>
          </aside>
        }
      </main>
    </section>
  )
}

export default Inquiry
