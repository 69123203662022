import React, { useState, useEffect, useContext, useRef } from 'react'
import { useLocation, useParams, Link } from 'react-router-dom'
import Deck from '../organisms/Deck.tsx'
import Paragraph1 from "../../components/atoms/text/Paragraph1"
import Header1 from "../../components/atoms/text/Header1.tsx"
import Header2 from "../../components/atoms/text/Header2.tsx"
import FormatDollars from "../../utils/FormatDollars";
import FormatInt from '../../utils/FormatInt'
import { CurrentUserContext } from '../../contexts/CurrentUser.js'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUpRightFromSquare, faDollarSign, faStore, faTag, faReceipt, faVenusMars, faShirt, faCalendarXmark, faBoxOpen, faShopLock} from '@fortawesome/free-solid-svg-icons'
import ProductNav from '../molecules/ProductNav.jsx'
import ProductDetailsImages from '../molecules/ProductDetailsImages.jsx'
import FormatCapitalize from "../../utils/FormatCapitalize";
import CreateOfferForm from '../molecules/CreateOfferForm.jsx'
import { OffersContext } from "../../contexts/Offers.js";
import { getOfferByProduct } from '../../utils/handleOffers.js'
import CtaLink from "~/components/atoms/buttons/CtaLink";


const ProductDetails = () => {
  let [product, setProduct] = useState()
  let location = useLocation()
  let { currentUser } = useContext(CurrentUserContext)
  let { offers, setOffers } = useContext(OffersContext)

  let params = useParams()
  let [products, setProducts] = useState([])
  let [brand, setBrand] = useState(null)
  let [productOfferStatus, setProductOfferStatus] = useState('none')

  const getProduct = () => {
    axios.get(`/marketplace/products/${params.product_id}`).then((pr) => {
      setProduct(pr.data)
      getBrand(pr.data.brand.id)
    })
  }

  const getProducts = () => {
    axios.get(`/marketplace/products/for_brand/${params.brand_id}`).then((pr) => {
      setProducts(pr.data)
    })
  }

  const getBrand = (id) => {
    axios.get(`/marketplace/brands/${id}`).then((r) => {
      setBrand(r.data)
    })
  }

  const getOfferStatus = () => {
    let associatedOffer = getOfferByProduct(offers, product)
    if (associatedOffer) {
      if (associatedOffer.state === 'draft' || associatedOffer.state === 'saved_for_later') {
        setProductOfferStatus('cart')
      } else if (associatedOffer.state === 'buyer_review' || associatedOffer.state === 'seller_review') {
        setProductOfferStatus('offers')
      } else {
        setProductOfferStatus('none')
      }
    } else {
      setProductOfferStatus('none')
    }

  }

  useEffect(() => {
    setProduct(null)
    getProducts()
    getProduct(params.product_id)
  }, [location])

  useEffect(() => {
    product && getOfferStatus()
  }, [product, offers])

  return (
    <section className={'mt-4 px-12 h-full w-full 2xl:w-[1440px] 2xl:mx-auto 2xl:p-0'}>
      <ProductNav productName={product?.name} />

      <div className={'flex'}>
        <section className={'w-1/2 lg:w-3/5 mr-2'}>
          <ProductDetailsImages images={product?.images} />
          {/* Description */}
          <div className={'mt-4 bg-white p-3 border-t border-secondary'}>
            <Header2>Product Description</Header2>
            <div className={'pt-2'}>
              <p className='text-sm font-reg' dangerouslySetInnerHTML={{__html: product?.description}}></p>
            </div>
          </div>

          <div className={'bg-white p-3 border-b border-secondary'}>
            <Link to={`/all_brands/${product?.brand.id}`} className={'flex items-center'}>
              <Header2 styles={'mr-3'}>Brand Description</Header2>
              <FontAwesomeIcon icon={faUpRightFromSquare} className={'h-4 w-4 text-primary'} />
            </Link>
            <div className={'pt-2'}>
              <Paragraph1>{brand?.description}</Paragraph1>
            </div>
          </div>
        </section>
        <section className={'w-1/2 lg:w-2/5 bg-white p-3 ml-2'}>
          {/* Details */}
              <header className={'w-full pb-2'}>
                <div className={'w-full flex justify-between mb-2'}>
                  <Header1 styles={''}>{product?.name}</Header1>
                </div>
                <div className={'py-2 flex justify-between border-b border-t border-secondary'}>
                  <Link to={`/all_brands/${product?.brand.id}`} className={'flex'}>
                    <Header2>{product?.brand?.name}</Header2>
                  </Link>
                  <p className={'text-red-600 text-xl font-reg mr-6'}>{`${product?.percent_off_msrp}% off MSRP`}</p>
                </div>
              </header>

            <div className={'py-2 flex flex-wrap'}>

              <div className={'my-1.5 w-full'}>
                <Header2>Price Details</Header2>
                <div className={'grid grid-cols-2 gap-x-2 gap-y-1 pt-2'}>
                  <div className={'flex items-center'}>
                    <FontAwesomeIcon icon={faTag} className={'h-4 w-4 mr-2'} />
                    <p className={'text-almost-black text-sm font-bold'}>MSRP per unit:</p>
                  </div>
                  <Paragraph1>{FormatDollars(product?.msrp_cents)}</Paragraph1>

                  <div className={'flex items-center'}>
                    <FontAwesomeIcon icon={faDollarSign} className={'h-4 w-4 mr-2'} />
                    <p className={'text-almost-black text-sm font-bold'}>Asking price per unit:</p>
                  </div>
                  <Paragraph1>{FormatDollars(product?.seller_price_cents)}</Paragraph1>

                  <div className={'flex items-center'}>
                    <FontAwesomeIcon icon={faStore} className={'h-4 w-4 mr-2'} />
                    <p className={'text-almost-black text-sm font-bold'}>Available units:</p>
                  </div>
                  {
                    product?.units > 0 ? <Paragraph1>{FormatInt(product?.units)}</Paragraph1>
                      : <p className={'text-dark text-sm font-bold'}>{"Sold out"}</p>

                  }

                  {
                    (product?.min_order_units) &&
                    <React.Fragment>
                      <div className={'flex items-center'}>
                        <FontAwesomeIcon icon={faShopLock} className={'h-4 w-4 mr-2'} />
                        <p className={'text-almost-black text-sm font-bold'}>MOQ:</p>
                      </div>
                      <Paragraph1>{FormatInt(product?.min_order_units)}</Paragraph1>
                    </React.Fragment>
                  }

                  <div className={'flex items-center'}>
                    <FontAwesomeIcon icon={faReceipt} className={'h-4 w-4 mr-2'} />
                    <p className={'text-almost-black text-sm font-bold'}>Total asking price:</p>
                  </div>
                  <Paragraph1>{FormatDollars(product?.units * product?.seller_price_cents)}</Paragraph1>
                </div>
              </div>

              {
                  ((product?.category == 'apparel' || product?.category == 'footwear' || product?.gender !== 'unisex') || product?.seller_size || product?.expiration_date || (product?.packaging_type !== "other packaging type")) &&
                  <div className={'my-1.5 w-full'}>
                    <Header2>Product Details</Header2>
                    <div className={'grid grid-cols-2 gap-x-2 gap-y-1 pt-2'}>
                      {
                        (product?.gender) &&
                        <React.Fragment>
                          <div className={'flex items-center'}>
                            <FontAwesomeIcon icon={faVenusMars} className={'h-4 w-4 mr-2'} />
                            <p className={'text-almost-black text-sm font-bold'}>Gender:</p>
                          </div>
                          <Paragraph1>{FormatCapitalize(product?.gender)}</Paragraph1>
                        </React.Fragment>
                      }
                      {
                        (product?.seller_size) &&
                        <React.Fragment>
                          <div className={'flex items-center'}>
                            <FontAwesomeIcon icon={faShirt} className={'h-4 w-4 mr-2'} />
                            <p className={'text-almost-black text-sm font-bold'}>Size:</p>
                          </div>
                          <Paragraph1>{product?.seller_size}</Paragraph1>
                        </React.Fragment>
                      }
                      {
                        (product?.expiration_date) &&
                        <React.Fragment>
                          <div className={'flex items-center'}>
                            <FontAwesomeIcon icon={faCalendarXmark} className={'h-4 w-4 mr-2'} />
                            <p className={'text-almost-black text-sm font-bold'}>Expiration date</p>
                          </div>
                          <Paragraph1>{product?.expiration_date}</Paragraph1>
                        </React.Fragment>
                      }
                      {
                        (product?.packaging_type && product?.packaging_type !== "other packaging type") &&
                        <React.Fragment>
                          <div className={'flex items-center'}>
                            <FontAwesomeIcon icon={faBoxOpen} className={'h-4 w-4 mr-2'} />
                            <p className={'text-almost-black text-sm font-bold'}>Packaging type:</p>
                          </div>
                          <Paragraph1>{FormatCapitalize(product?.packaging_type)}</Paragraph1>
                        </React.Fragment>
                      }
                    </div>
                  </div>
                }

              {
                !currentUser?.retailer || !product?.units ? null : productOfferStatus === 'cart' ?
                  <div className={
                    "my-1.5 py-3 w-full flex flex-col"
                  }>
                    <CtaLink id={'product-details-link-to-cart'} link={'/cart'}>View in Cart</CtaLink>
                  </div>
                  : productOfferStatus === 'offers' ?
                    <div className={
                      "my-1.5 py-3 w-full flex flex-col"
                    }>
                      <CtaLink id={'product-details-link-to-cart'} link={'/my_offers'}>View in Offers</CtaLink>
                    </div>
                  : <CreateOfferForm product={product} />
              }
            </div>
        </section>
      </div>
      {/* More Products */}
      <div className={'mt-4 py-4 bg-white bg-opacity-60 grid place-content-center'}>
        <Deck headerText={'More from this brand'} products={products} />
      </div>
    </section>
  )
}

export default ProductDetails
