import React, { useEffect, useState, useRef, useContext } from 'react'
import { Outlet, useLocation, useNavigate, Navigate } from 'react-router-dom'
import AccountHeader from '../components/organisms/headers/AccountHeader.tsx'
import Footer from '../components/organisms/Footer.tsx'
import SignUpModal from '../components/organisms/modals/SignUpModal.tsx'
import SignInModal from '../components/organisms/modals/SignInModal.tsx'
import Modal from '../components/organisms/modals/Modal.tsx'
import { CurrentUserContext } from "../contexts/CurrentUser.js"
import ScrollToTop from '../components/ScrollToTop'
import Auth from '../utils/Auth.js'
import axios from 'axios'
import { ProductsContext } from "../contexts/Products.js"
import ForgotPasswordModal from "~/components/organisms/modals/ForgotPasswordModal"
import ReactGA from 'react-ga4'
import ReleaseNotesModal from '../components/organisms/modals/ReleaseNotesModal.jsx'
import BetterOnDesktop from '../components/organisms/modals/BetterOnDesktopModal.jsx'
import Logo from "../entrypoints/images/logo240.webp"
import { CartSkusContext } from '../contexts/CartSkus.js'
import { OffersContext } from '../contexts/Offers.js'
import { OrdersContext } from '../contexts/Orders.js'
import { parseOffers } from '../utils/handleOffers.js'

const Home = () => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const { currentUser, setCurrentUser } = useContext(CurrentUserContext)
  const [cartSkus, setCartSkus] = useState(0)
  const [offers, setOffers] = useState([])
  const [orders, setOrders] = useState([])
  const auth = Auth(setCurrentUser)
  const navigate = useNavigate()
  const location = useLocation()
  const modalRef = useRef(null)

  const [signUpModalOpen, setSignUpModalOpen] = useState(false)
  const [signInModalOpen, setSignInModalOpen] = useState(false)
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false)
  const [products, setProducts] = useState([])
  const [favorites, setFavorites] = useState([])
  const [brands, setBrands] = useState([])
  const [tags, setTags] = useState([])
  let [releaseNotes, setReleaseNotes] = useState([])
  let [releaseNotesModalOpen, setReleaseNotesModalOpen] = useState(false)
  let [mobileDismissed, setMobileDismissed] = useState(false)

  const signOut = () => {
    auth.logout('/')
  }

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search })
    if (location.pathname.includes('sign_in')) {
      navigate('/')
      setSignInModalOpen(true)
      setSignUpModalOpen(false)
      setForgotPasswordModalOpen(false)
    }
    if (location.pathname.includes('sign_up')) {
      navigate('/')
      setSignInModalOpen(false)
      if (!currentUser.loading) { setSignUpModalOpen(true) }
      setForgotPasswordModalOpen(false)
    }
    if (location.pathname.includes('forgot_password')) {
      navigate('/')
      setSignInModalOpen(false)
      setSignUpModalOpen(false)
      setForgotPasswordModalOpen(true)
    }
  }, [location])


  let userPrompt = useRef()
  useEffect(() => {
    const smScreen = window.matchMedia("(min-width: 640px)")

    if (currentUser.email) {
      clearTimeout(userPrompt.current)
    } else {
      clearTimeout(userPrompt.current)
      if (!signUpModalOpen && !signInModalOpen && !forgotPasswordModalOpen) {
        if (!smScreen.matches) {
          setSignUpModalOpen(true)
        } else {
//         userPrompt.current = setTimeout(() => {
//           setSignUpModalOpen(true)
//         }, 10000)
        }
      } else {
        clearTimeout(userPrompt.current)
      }
    }
  }, [signUpModalOpen, signInModalOpen, forgotPasswordModalOpen, currentUser, location])

  const handleClick = (e) => {
    return true
    // if (currentUser.loading) {
    //   return false
    // } else {
    //   if (!currentUser?.email && e.target.id !== 'banner-retailer-sign-up-link' && e.target.id !== 'banner-brand-sign-up-link' && e.target.id !== 'nav-about-link') {
    //     navigate('/sign_up')
    //   }
    // }
  }

  const parseOrders = (unparsedOrders) => {
    let parsedOrders = []
    let purchase_order_products = unparsedOrders.included

    unparsedOrders.data.forEach((o) => {
      let obj = o.attributes
      obj.purchase_order_products = o.relationships.purchase_order_products.data.map((elem) => {
        return purchase_order_products.find(op => op.id === elem.id)
      })
      parsedOrders.push(obj)
    })

    return parsedOrders.sort((a, b) => {
      return a.created_at - b.created_at
    })
  }

  useEffect(() => {
    const smScreen = window.matchMedia("(min-width: 640px)")
    if (currentUser?.brand && !currentUser?.brand.description) {
       navigate('/brand_inquiry/create_profile')
    }
    if (currentUser?.retailer) {
      currentUser?.status === 'approved' && navigate('/retailers_create_password')
    }
    currentUser?.release_notes?.length && setReleaseNotesModalOpen(true)
    !currentUser.email && setMobileDismissed(false)
    axios.get('/offers').then((res) => {
      setOffers(parseOffers(res.data))
    })
    axios.get('/marketplace/purchase_orders').then(res => {
      setOrders(parseOrders(res.data))
    })
  }, [currentUser])

  const handleSignInClick = () => {
    setSignInModalOpen(true)
    setSignUpModalOpen(false)
  }

  return (
    <ProductsContext.Provider value={{products, setProducts, favorites, setFavorites, brands, setBrands, tags, setTags}}>
      <CartSkusContext.Provider value={{ cartSkus, setCartSkus }}>
        <OffersContext.Provider value={{ offers, setOffers }}>
          <OrdersContext.Provider value={{ orders, setOrders }}>
            <div className={`${currentUser?.email && mobileDismissed ? 'block' : 'hidden'} sm:block fixed h-screen w-screen top-0 right-0 overflow-y-scroll bg-white`} id={'fixed-home'}>
              <AccountHeader openSignInModal={handleSignInClick} signOut={signOut} />
              <main
                className={
                  'mt-20 min-h-[calc(100%-176px)] flex flex-col items-center'
                }
                onClick={handleClick}
              >
                {(currentUser?.brand && location.pathname === '/') ? <Navigate to='/dashboard' replace/> : <Outlet />}
              </main>
              <Footer openSignUpModal={() => setSignUpModalOpen(true)} />
              <Modal isOpen={signUpModalOpen} setIsOpen={setSignUpModalOpen}>
                <SignUpModal />
              </Modal>
              <Modal isOpen={signInModalOpen} setIsOpen={setSignInModalOpen}>
                <SignInModal setIsOpen={setSignInModalOpen} />
              </Modal>
              <Modal isOpen={forgotPasswordModalOpen} setIsOpen={setForgotPasswordModalOpen}>
                <ForgotPasswordModal setIsOpen={setForgotPasswordModalOpen} />
              </Modal>
              <ReleaseNotesModal isOpen={releaseNotesModalOpen} setIsOpen={setReleaseNotesModalOpen} notes={currentUser?.release_notes} />
              <ScrollToTop elem={document.getElementById('fixed-home')} />
            </div>
            <div className={`${mobileDismissed ? 'hidden' : 'flex'} sm:hidden h-screen w-screen bg-blue`}>
              <div className={'w-full bg-white rounded-3xl mx-2 sm:mx-4 my-4 shadow-2xl pb-2 overflow-hidden'} ref={modalRef}>
                <div className={'h-full overflow-y-scroll'}>
                  <img src={Logo} alt={'made me buy it logo'} className={'h-16 mx-auto mt-4'} />
                  {
                    currentUser?.email ?
                      <React.Fragment>
                        <BetterOnDesktop setDismiss={setMobileDismissed} />
                      </React.Fragment>
                      :
                      <React.Fragment>
                        <div className={`${!signUpModalOpen && 'hidden'}`}>
                          <SignUpModal isOpen={signUpModalOpen} setIsOpen={setSignUpModalOpen} />
                        </div>
                        <div className={!signInModalOpen && 'hidden'}>
                          <SignInModal setIsOpen={setSignInModalOpen} />
                        </div>
                        <div className={!forgotPasswordModalOpen && 'hidden'}>
                          <ForgotPasswordModal setIsOpen={setForgotPasswordModalOpen} />
                        </div>
                      </React.Fragment>
                  }
                </div>
              </div>
            </div>
          </OrdersContext.Provider>
        </OffersContext.Provider>
      </CartSkusContext.Provider>
    </ProductsContext.Provider>
  )
}

export default Home
