import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'

const MessageSlider = ({ showMessage, setShowMessage, message, setMessage, success }) => {

  let handleClose = () => {
    setShowMessage(false)
  }

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        setShowMessage(false)
      }, 5000)
    }
  }, [showMessage])

  return (
    <div className={`
      w-screen fixed top-24 z-50 transition-transform transform duration-2500 inset-x-0
      ${showMessage ? "translate-x-0" : "translate-x-full"}
      `}>
      <div className={`
      ${success ? "bg-green-400" : "bg-red-600"} w-72 relative bg-opacity-20 z-50 p-3 text-center rounded-md shadow-xl mx-auto
      `}>
        <button className={'absolute -top-1 -left-1'} onClick={handleClose}>
          <FontAwesomeIcon icon={faCircleXmark} className={'h-4 text-secondary'} alt={'close'} />
        </button>
        <h1 className={`${success ? "text-green-800" : "text-red-600"}`}>{message}</h1>
      </div>
    </div>
  )
}

export default MessageSlider
