import React, { useState, useEffect } from 'react'
import SortDropDown from "~/components/molecules/inputs/SortDropDown";
import FilterButton from './atoms/buttons/FilterButton.tsx'
import FiltersModal from './organisms/modals/FiltersModal.tsx'
import { useLocation } from 'react-router-dom'

const SortAndFilterBar = ({ filters, setFilters, sort, handleSortChange, numProducts }) => {
  let location = useLocation()

  let [filteredProductCount, setFilteredProductCount] = useState(null)

// let msrpRef = useRef(null)
// let followersRef = useRef(null)
// let salesRef = useRef(null)
// let retailersRef = useRef(null)
// let unitsAvailableRef = useRef(null)
// let percentOffMsrpRef = useRef(null)

// let filterValues = {
//   msrp: ['$0', '$5', '$10', '$15', '$20', '$30', 'max'],
//   percentOffMsrp: ['50%', '60%', '70%', '80%', '90%', '95%', 'max']
// }

  let [msrpModified, setMsrpModified] = useState(false)
  let [percentOffMsrpModified, setPercentOffMsrpModified] = useState(false)
  let [sellerPriceCentsModified, setSellerPriceCentsModified] = useState(false)
  let [unitsAvailableModified, setUnitsAvailableModified] = useState(false)
  let [packagingTypeModified, setPackagingTypeModified] = useState(false)
// let [followersModified, setFollowersModified] = useState(false)
// let [salesModified, setSalesModified] = useState(false)
// let [retailersModified, setRetailersModified] = useState(false)

// let [msrpOpen, setMsrpOpen] = useState(false)
// let [followersOpen, setFollowersOpen] = useState(false)
// let [salesOpen, setSalesOpen] = useState(false)
// let [retailersOpen, setRetailersOpen] = useState(false)
  let [filtersModalOpen, setFiltersModalOpen] = useState(false)

  // let handleMsrpOpen = () => {
  //   msrpRef.current.hidden = !msrpRef.current.hidden
  //   setMsrpOpen(!msrpOpen)
  // }
  // let handleFollowersOpen = () => {
  //   followersRef.current.hidden = !followersRef.current.hidden
  //   setFollowersOpen(!followersOpen)
  // }
  // let handleSalesOpen = () => {
  //   salesRef.current.hidden = !salesRef.current.hidden
  //   setSalesOpen(!salesOpen)
  // }
  // let handleRetailersOpen = () => {
  //   retailersRef.current.hidden = !retailersRef.current.hidden
  //   setRetailersOpen(!retailersOpen)
  // }

  const setSellerPriceCentsFilters = (min, max) => {
    if (min !== filters.sellerPriceCents.min || max !== filters.sellerPriceCents.max) {
      setFilters({...filters, sellerPriceCents: { min: min, max: max}})
    }
    if (min === 0 && max === 6) {
      setSellerPriceCentsModified(false)
    } else {
      setSellerPriceCentsModified(true)
    }
  }

  const setMsrpFilters = (min, max) => {
    if (min !== filters.msrp.min || max !== filters.msrp.max) {
      setFilters({...filters, msrp: { min: min, max: max}})
    }
    if (min === 0 && max === 6) {
      setMsrpModified(false)
    } else {
      setMsrpModified(true)
    }
  }

  const setPercentOffMsrpFilters = (min, max) => {
    if (min !== filters.percentOffMsrp.min || max !== filters.percentOffMsrp.max) {
      setFilters({...filters, percentOffMsrp: { min: min, max: max}})
    }
    if (min === 0 && max === 6) {
      setPercentOffMsrpModified(false)
    } else {
      setPercentOffMsrpModified(true)
    }
  }

  const setUnitsAvailableFilters = (min, max) => {
    if (min !== filters.unitsAvailable.min || max !== filters.unitsAvailable.max) {
      setFilters({...filters, unitsAvailable: { min: min, max: max}})
    }
    if (min === 0 && max === 6) {
      setUnitsAvailableModified(false)
    } else {
      setUnitsAvailableModified(true)
    }
  }

  const setPackagingTypeFilters = (v) => {
    setFilters({...filters, packagingType: v})
  }

  const resetFilters = () => {
    let initFilters = {
      msrp: {
        min: 0,
        max: 6,
      },
      percentOffMsrp: {
        min: 0,
      },
      sellerPriceCents: {
        min: 0,
        max: 6
      },
      unitsAvailable: {
        min: 0,
      },
      packagingType: ''
    }
    setFilters(initFilters)
    handleSortChange('newest')
    setMsrpModified(false)
    setPercentOffMsrpModified(false)
    setUnitsAvailableModified(false)
    setPackagingTypeModified(false)
    setSellerPriceCentsModified(false)
  }

  // let [filtersApplied, setFiltersApplied] = useState(false)
  let [appliedFilterCount, setAppliedFilterCount] = useState(0)

  useEffect(() => {
    let fCount = 0;
    [msrpModified, percentOffMsrpModified, unitsAvailableModified, packagingTypeModified, sellerPriceCentsModified].forEach((f) => {
      f && fCount++
    })
    setAppliedFilterCount(fCount)
  }, [filters])

  useEffect(() => {
    if (!msrpModified && !percentOffMsrpModified && !unitsAvailableModified && !packagingTypeModified && !sellerPriceCentsModified) {
      setFilteredProductCount('Show All Products')
    } else if (!numProducts) {
      setFilteredProductCount('No matching products')
    } else {
      setFilteredProductCount(`Show ${numProducts} Products`)
    }
  }, [numProducts])

  useEffect(() => {
    if (location.search === '?filter=My%20Favorites') {
      resetFilters()
    }
  }, [location])

  return (
    <section className={'h-full w-full flex justify-end items-center gap-2 xl:gap-4 flex-wrap'}>
      <FilterButton
        openFiltersModal={() => setFiltersModalOpen(true)}
        clearFilters={resetFilters}
        numFiltersSet={appliedFilterCount}
      />
      <SortDropDown
        sortValue={sort}
        setSort={handleSortChange}
      />
      <FiltersModal
        isOpen={filtersModalOpen}
        filters={filters}
        setIsOpen={setFiltersModalOpen}
        setMsrp={setMsrpFilters}
        setSellerPriceCents={setSellerPriceCentsFilters}
        setUnitsAvailable={setUnitsAvailableFilters}
        setPercentOffMsrp={setPercentOffMsrpFilters}
        setPackagingType={setPackagingTypeFilters}
        sortValue={sort}
        setSort={handleSortChange}
        numProducts={filteredProductCount}
        reset={resetFilters}
      />
    </section>
  )
}

export default SortAndFilterBar
