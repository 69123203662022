import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function Insights() {

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    trailingZeroDisplay: 'stripIfInteger'
  });

  axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  const [insights, setInsights] = useState({
    purchaseOrder: [],
    product: [],
    user: [],
    offer: [],
  });

  const getInsights = (resourceType) => {
    axios.get(`/admin/insights/${resourceType}.json`)
    .then(response => {
      setInsights(insights => ({ ...insights, [resourceType]: response.data }));
    })
    .catch(error => console.error(error));
  }

  const buyers = () => {
    return insights.user.filter(u => u.retailer_id?.length)
  }

  const sellers = () => {
    return insights.user.filter(u => u.brand_id?.length)
  }

  useEffect(() => {
    getInsights('purchaseOrder');
    getInsights('product');
    getInsights('user');
    getInsights('offer');
  }, []);

  const constructorFx = (sortableType) => {
    switch (sortableType) {
      case Date:
        return (date) => new Date(date);
      case String:
        return ((string) => {
          let s = String(string)
          return s.toLowerCase();
        })
      default:
        return (number) => number;
    }
  }

  const sortByAsc = (column, resourceType, sortableType) => {
    switch (sortableType) {
      case Date:
        let cfx = constructorFx(sortableType);
        setInsights(insights => ({...insights, [resourceType]: insights[resourceType].sort((a, b) => cfx(a[column]) - cfx(b[column]))}));
        break;
      case String:
        setInsights(insights => ({...insights, [resourceType]: insights[resourceType].sort((a, b) => a[column].localeCompare(b[column]))}));
        break;
      default:
        setInsights(insights => ({...insights, [resourceType]: insights[resourceType].sort((a, b) => a[column] - b[column])}));
    }
  }

  const sortByDesc = (column, resourceType, sortableType) => {
    switch (sortableType) {
      case Date:
        let cfx = constructorFx(sortableType);
        setInsights(insights => ({...insights, [resourceType]: insights[resourceType].sort((a, b) => cfx(b[column]) - cfx(a[column]))}));
        break;
      case String:
        setInsights(insights => ({...insights, [resourceType]: insights[resourceType].sort((a, b) => b[column].localeCompare(a[column]))}));
        break;
      default:
        setInsights(insights => ({...insights, [resourceType]: insights[resourceType].sort((a, b) => b[column] - a[column])}));
    }
  }

  const onboarded = (users) => {
    return users.filter(u => u.status === 'onboarded');
  }

  const approved = (users) => {
    return users.filter(u => u.status === 'approved' || u.status === 'password_created');
  }

  const denied = (users) => {
    return users.filter(u => u.status === 'denied');
  }

  const downloadCSV = (resource_type) => {
    console.log('downloading csv')
    axios({
      url: `/admin/insights/download_csv/${resource_type}.csv`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${resource_type}.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    })
  }

  return (
    <div className='font-reg text-sm ml-2'>
      <h1 className='font-bold text-3xl my-4'>Insights</h1>
      <hr/>
      <div onClick={() => document.getElementById('purchaseOrders').classList.toggle('hidden')} className='mt-4'>
        <h2 className='font-bold'>Purchase Orders ({insights.purchaseOrder.length})</h2>
      </div>
      <div id='purchaseOrders' className='hidden ml-8 mb-4'>
        <div>
          <div onClick={() => downloadCSV('purchase_order')} className='text-blue-500 underline'>Download CSV</div>
          <span onClick={() => sortByAsc('created_at', 'purchaseOrder', Date)} className='text-purple-500 underline'>newest</span>&nbsp;
          <span onClick={() => sortByDesc('created_at', 'purchaseOrder', Date)} className='text-purple-500 underline'>oldest</span>
        </div>
        <div className="grid grid-flow-col auto-cols-fr font-bold">
          {/* when user clicks the header, sort the purchase orders by the column */}
          <div className=''> </div>
          <div className='' onClick={() => setInsights(insights => ({...insights, purchaseOrder: insights.purchaseOrder.sort((a, b) => a.seller_email.localeCompare(b.seller_email))}))}>
            Seller Email
          </div>
          <div className='' onClick={() => setInsights(insights => ({...insights, purchaseOrder: insights.purchaseOrder.sort((a, b) => a.buyer_email.localeCompare(b.buyer_email))}))}>
            Buyer Email
          </div>

          <div onClick={() => setInsights(insights => ({...insights, purchaseOrder: insights.purchaseOrder.sort((a, b) => a.status.localeCompare(b.status))}))}>
            Status
          </div>
          <div onClick={() => setInsights(insights => ({...insights, purchaseOrder: insights.purchaseOrder.sort((a, b) => a.total_cents - b.total_cents)}))}>
            Total
          </div>
          <div onClick={() => setInsights(insights => ({...insights, purchaseOrder: insights.purchaseOrder.sort((a, b) => a.total_net_to_seller - b.total_net_to_seller)}))}>
            Total Net to Seller
          </div>
          <div onClick={() => setInsights(insights => ({...insights, purchaseOrder: insights.purchaseOrder.sort((a, b) => (a.total_cents - a.total_net_to_seller) - (b.total_cents - b.total_net_to_seller))}))}>
            Profit
          </div>
          <div onClick={() => setInsights(insights => ({...insights, purchaseOrder: insights.purchaseOrder.sort((a, b) => a.total_units - b.total_units)}))}>
            Total Units
          </div>
          <div onClick={() => setInsights(insights => ({...insights, purchaseOrder: insights.purchaseOrder.sort((a, b) => a.sku_count - b.sku_count)}))}>
            SKU Count
          </div>
        </div>
      {insights.purchaseOrder.map((purchaseOrder, i) => (
        <div className="grid grid-flow-col auto-cols-fr" key={purchaseOrder.id}>
          <div className='text-center overflow-x-scroll scrollbar-hide mr-2'>{i}</div>
          <div className='overflow-x-scroll scrollbar-hide text-nowrap mr-2'>{purchaseOrder.seller_email}</div>
          <div className='overflow-x-scroll scrollbar-hide text-nowrap mr-2'>{purchaseOrder.buyer_email}</div>
          <div className='overflow-x-scroll scrollbar-hide text-nowrap mr-2'>{purchaseOrder.status}</div>
          <div className='overflow-x-scroll scrollbar-hide text-nowrap mr-2'>{formatter.format(purchaseOrder.total_cents/100.0)}</div>
          <div className='overflow-x-scroll scrollbar-hide text-nowrap mr-2'>{formatter.format(purchaseOrder.total_net_to_seller/100.0)}</div>
          <div className='overflow-x-scroll scrollbar-hide text-nowrap mr-2'>{formatter.format(purchaseOrder.total_cents/100.0 - purchaseOrder.total_net_to_seller/100.0)}</div>
          <div className='overflow-x-scroll scrollbar-hide text-nowrap mr-2'>{purchaseOrder.total_units}</div>
          <div className='overflow-x-scroll scrollbar-hide text-nowrap mr-2'>{purchaseOrder.sku_count}</div>
        </div>
      ))}
      </div>
      <div onClick={() => document.getElementById('products').classList.toggle('hidden')}>
        <h2 className='font-bold'>Products ({insights.product.length}) ({insights.product.reduce((col, el) => col + el.units, 0)})</h2>
      </div>
      <div id='products' className='hidden ml-8 mb-4'>
        <div>
          <div onClick={() => downloadCSV('product')} className='text-blue-500 underline'>Download CSV</div>
          <span onClick={() => sortByAsc('name', 'product', String)} className='text-purple-500 underline'>A-Z</span>&nbsp;
          <span onClick={() => sortByDesc('name', 'product', String)} className='text-purple-500 underline'>Z-A</span>
        </div>
        <div className="grid grid-flow-col auto-cols-fr font-bold">
          <div> </div>
          <div onClick={() => setInsights(insights => ({...insights, product: insights.product.sort((a, b) => (a.name || '').localeCompare(b.name || ''))}))}>
            Name
          </div>
          <div onClick={() => setInsights(insights => ({...insights, product: insights.product.sort((a, b) => a.brand_name.localeCompare(b.brand_name))}))}>
            Brand
          </div>
          <div onClick={() => setInsights(insights => ({...insights, product: insights.product.sort((a, b) => (toString(a.provisional) || '') - (toString(b.provisional) || ''))}))}>
            Status
          </div>
          <div onClick={() => setInsights(insights => ({...insights, product: insights.product.sort((a, b) => a.units - b.units)}))}>
            Units
          </div>
        </div>
        {insights.product.map((product, i) => (
          <div className="grid grid-flow-col auto-cols-fr" key={product.id}>
            <div className='text-center overflow-x-scroll scrollbar-hide mr-2'>{i}</div>
            <div className='overflow-x-scroll scrollbar-hide text-nowrap mr-2'>{product.name}</div>
            <div className='overflow-x-scroll scrollbar-hide text-nowrap mr-2'>{product.brand_name}</div>
            <div className='overflow-x-scroll scrollbar-hide text-nowrap mr-2'>{product.provisional ? 'provisional' : 'live'}</div>
            <div className='overflow-x-scroll scrollbar-hide text-nowrap mr-2'>{product.units}</div>
          </div>
        ))}
      </div>
      <div onClick={() => document.getElementById('buyers').classList.toggle('hidden')}>
        <h2 className='font-bold'>Buyers ({buyers().length}) <span className='text-green-500'>{onboarded(buyers()).length}</span> onboarded, <span className='text-amber-500'>{approved(buyers()).length}</span> approved, <span className='text-red-500'>{denied(buyers()).length}</span> denied</h2>
      </div>
      <div id='buyers' className='hidden ml-8 mb-4'>
        <div>
          <div onClick={() => downloadCSV('user')} className='text-blue-500 underline'>Download CSV</div>
          <span onClick={() => sortByAsc('email', 'user', String)} className='text-purple-500 underline'>A-Z</span>&nbsp;
          <span onClick={() => sortByDesc('email', 'user', String)} className='text-purple-500 underline'>Z-A</span>
        </div>
        <div>
          <span onClick={() => sortByAsc('created_at', 'user', Date)} className='text-purple-500 underline'>newest</span>&nbsp;
          <span onClick={() => sortByDesc('created_at', 'user', Date)} className='text-purple-500 underline'>oldest</span>
        </div>
        <div className="grid grid-cols-3 font-bold">
          <div> </div>
          <div onClick={() => setInsights(insights => ({...insights, user: buyers().sort((a, b) => a.email.localeCompare(b.email))}))}>
            Email
          </div>
          <div onClick={() => setInsights(insights => ({...insights, user: buyers().sort((a, b) => a.status.localeCompare(b.status))}))}>
            Status
          </div>
        </div>
        {buyers().map((user, i) => (
          <div className="grid grid-cols-3" key={user.id}>
            <div class='text-center'>{i}</div>
            <div>{user.email}</div>
            <div>{user.status}</div>
          </div>
        ))}
      </div>
      <div onClick={() => document.getElementById('sellers').classList.toggle('hidden')}>
        <h2 className='font-bold'>Sellers ({sellers().length}) <span className='text-green-500'>{onboarded(sellers()).length}</span> onboarded, <span className='text-amber-500'>{approved(sellers()).length}</span> approved, <span className='text-red-500'>{denied(sellers()).length}</span> denied</h2>
      </div>
      <div id='sellers' className='hidden ml-8 mb-4'>
        <div>
          <div onClick={() => downloadCSV('user')} className='text-blue-500 underline'>Download CSV</div>
          <span onClick={() => sortByAsc('email', 'user', String)} className='text-purple-500 underline'>A-Z</span>&nbsp;
          <span onClick={() => sortByDesc('email', 'user', String)} className='text-purple-500 underline'>Z-A</span>
        </div>
        <div>
          <span onClick={() => sortByAsc('created_at', 'user', Date)} className='text-purple-500 underline'>newest</span>&nbsp;
          <span onClick={() => sortByDesc('created_at', 'user', Date)} className='text-purple-500 underline'>oldest</span>
        </div>
        <div className="grid grid-cols-3 font-bold">
          <div> </div>
          <div onClick={() => setInsights(insights => ({...insights, user: sellers().sort((a, b) => a.email.localeCompare(b.email))}))}>
            Email
          </div>
          <div onClick={() => setInsights(insights => ({...insights, user: sellers().sort((a, b) => a.status.localeCompare(b.status))}))}>
            Status
          </div>
        </div>
        {sellers().map((user, i) => (
          <div className="grid grid-cols-3" key={user.id}>
            <div class='text-center'>{i}</div>
            <div>{user.email}</div>
            <div>{user.status}</div>
          </div>
        ))}
      </div>
      <div onClick={() => document.getElementById('offers').classList.toggle('hidden')}>
        <h2 className='font-bold'>Offers ({insights.offer.length})</h2>
      </div>
      <div id='offers' className='hidden ml-8 mb-4'>
        <div>
          <div onClick={() => downloadCSV('offer')} className='text-blue-500 underline'>Download CSV</div>
          <span onClick={() => sortByAsc('created_at', 'offer', Date)} className='text-purple-500 underline'>newest</span>&nbsp;
          <span onClick={() => sortByDesc('created_at', 'offer', Date)} className='text-purple-500 underline'>oldest</span>
        </div>
        <div className="grid grid-flow-col auto-cols-fr font-bold">
          <div> </div>
          <div onClick={() => setInsights(insights => ({...insights, offer: insights.offer.sort((a, b) => a.buyer_email.localeCompare(b.buyer_email))}))}>
            Buyer Email
          </div>
          <div onClick={() => setInsights(insights => ({...insights, offer: insights.offer.sort((a, b) => a.seller_email.localeCompare(b.seller_email))}))}>
            Seller Email
          </div>
          <div onClick={() => setInsights(insights => ({...insights, offer: insights.offer.sort((a, b) => a.state.localeCompare(b.state))}))}>
            Status
          </div>
          <div onClick={() => setInsights(insights => ({...insights, offer: insights.offer.sort((a, b) => a.total_cents - b.total_cents)}))}>
            Total
          </div>
          <div onClick={() => setInsights(insights => ({...insights, offer: insights.offer.sort((a, b) => a.sku_count - b.sku_count)}))}>
            SKU Count
          </div>
          <div onClick={() => setInsights(insights => ({...insights, offer: insights.offer.sort((a, b) => a.total_units - b.total_units)}))}>
            Total Units
          </div>
        </div>
        {insights.offer.map((offer, i) => (
          <div className="grid grid-flow-col auto-cols-fr" key={offer.id}>
            <div class='text-center'>{i}</div>
            <div className='overflow-x-scroll scrollbar-hide mr-2 text-nowrap'>{offer.buyer_email}</div>
            <div className='overflow-x-scroll scrollbar-hide mr-2 text-nowrap'>{offer.seller_email}</div>
            <div className='overflow-x-scroll scrollbar-hide mr-2 text-nowrap'>{offer.state}</div>
            <div className='overflow-x-scroll scrollbar-hide mr-2 text-nowrap'>{formatter.format(offer.total_cents/100.0)}</div>
            <div className='overflow-x-scroll scrollbar-hide mr-2 text-nowrap'>{offer.sku_count}</div>
            <div className='overflow-x-scroll scrollbar-hide mr-2 text-nowrap'>{offer.total_units}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
