import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSliders, faXmark } from '@fortawesome/free-solid-svg-icons'
import Header2 from "~/components/atoms/text/Header2";

const FilterButton = ({ openFiltersModal, clearFilters, numFiltersSet }: { openFiltersModal: Function, clearFilters: Function, numFiltersSet: number }) => {

  return (
    <div
      className={'flex justify-between items-center bg-white p-4 rounded-full shadow-xl'}
      id={'filter-button'}
    >
      <button onClick={openFiltersModal} className={'flex justify-center items-center'}>
        <FontAwesomeIcon icon={faSliders} className={'h-6 w-6 text-primary mr-2'} />
        <Header2>Filter Products</Header2>
      </button>
      {
        numFiltersSet !== 0 &&
          <React.Fragment>
            <Header2 styles={'mx-2'}>{`(${numFiltersSet})`}</Header2>
            <button
              onClick={clearFilters}
              className={'flex justify-center items-center'}
              id={'filter-button-clear-button'}
            >
              <FontAwesomeIcon icon={faXmark} className={'h-6 w-6 text-primary'} />
            </button>
          </React.Fragment>
      }
    </div>
  )
}

export default FilterButton
