import * as React from "react"
import { createRoot } from "react-dom/client"
import Navigation from "./components/Navigation.tsx"
import CuratePicks from "./components/CuratePicks.jsx"
import CategoryCuration from "./components/CategoryCuration.jsx"
import PrePick from "./components/PrePick.jsx"
import CreateReleaseNote from "./components/CreateReleaseNote.jsx"
import BrandCuration from "./components/BrandCuration.jsx"
import CuratedBrandsProducts from "./components/CuratedBrandsProducts.jsx"
import SamplesSignUp from "./components/SamplesSignUp.jsx"
import ManagePurchaseOrders from "./components/ManagePurchaseOrders.jsx"
import Insights from "./components/Insights.jsx"

import ReactGA from 'react-ga4';

ReactGA.initialize('G-FVS60WHVXY');

document.addEventListener("DOMContentLoaded", () => {
  let container = document.getElementById("top-navigation")
  let root
  if (container) {
    root = createRoot(container)
  }
  root && root.render(<Navigation />)

  let curatePicksContainer = document.getElementById("curate-picks")
  if (curatePicksContainer) {
    let curatePicksRoot = createRoot(curatePicksContainer)
    curatePicksRoot && curatePicksRoot.render(<CuratePicks />)
  }

  let managePurchaseOrdersContainer = document.getElementById("manage-purchase-orders")
  if (managePurchaseOrdersContainer) {
    let managePurchaseOrdersRoot = createRoot(managePurchaseOrdersContainer)
    managePurchaseOrdersRoot && managePurchaseOrdersRoot.render(<ManagePurchaseOrders />)
  }

  let prePickContainer = document.getElementById("pre-pick")
  if (prePickContainer) {
    let prePickRoot = createRoot(prePickContainer)
    prePickRoot && prePickRoot.render(<PrePick />)
  }

  let releaseNotesContainer = document.getElementById("release-notes")
  if (releaseNotesContainer) {
    let releaseNotesRoot = createRoot(releaseNotesContainer)
    releaseNotesRoot && releaseNotesRoot.render(<CreateReleaseNote />)
  }

  let categoryCurationContainer = document.getElementById("category-curation")
  if (categoryCurationContainer) {
    let categoryCurationRoot = createRoot(categoryCurationContainer)
    categoryCurationRoot && categoryCurationRoot.render(<CategoryCuration />)
  }

  let brandCuration = document.getElementById("brand-curation")
  if (brandCuration) {
    let brandCurationRoot = createRoot(brandCuration)
    brandCurationRoot && brandCurationRoot.render(<BrandCuration />)
  }

  let curatedBrandsProducts = document.getElementById("curated-brands-products")
  if (curatedBrandsProducts) {
    let curatedBrandsProductsRoot = createRoot(curatedBrandsProducts)
    curatedBrandsProductsRoot && curatedBrandsProductsRoot.render(<CuratedBrandsProducts />)
  }

  let samplesContainer = document.getElementById("samples-sign-up")
  if (samplesContainer) {
    let samplesRoot = createRoot(samplesContainer)
    samplesRoot && samplesRoot.render(<SamplesSignUp />)
  }

  let insightsContainer = document.getElementById("admin-insights")
  if (insightsContainer) {
    let insightsRoot = createRoot(insightsContainer)
    insightsRoot && insightsRoot.render(<Insights />)
  }
})
