import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import Header2 from "../../../components/atoms/text/Header2.tsx"
import { OffersContext } from "../../../contexts/Offers.js"
import Offer from "../../molecules/Offer.jsx"
import Header1 from '../../../components/atoms/text/Header1'


export default function OffersIndex() {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const { offers } = useContext(OffersContext)
  const [filteredOffers, setFilteredOffers] = useState([])

  useEffect(() => {
    setFilteredOffers(offers.filter(o => o.state !== 'draft' && o.state !== 'saved_for_later' && o.state !== 'accepted'))
  }, [offers])

  return(
    <section className={'w-[1020px] bg-light my-6'}>
      <Header1>Offers</Header1>
      {
        !filteredOffers?.length && <Header2 styles={'text-center my-12'}>You have no offers.</Header2>
      }
      {
        filteredOffers?.map((o) => {
          return <Offer offer={o} />
        })
      }
    </section>
  )
}
