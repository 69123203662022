import React, { useState, useEffect } from 'react'
import Header2 from "../../atoms/text/Header2"
import React, { useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleUp, faAngleDown, faSort} from '@fortawesome/free-solid-svg-icons'

const SortDropDown = ({ sortValue, setSort }: { sortValue: string, setSort: Function }) => {

  const handleSubmit = (value) => {
    setSort(value)
    setIsOpen(false)
  }

  let [isOpen, setIsOpen] = useState(false)

  let sortRef = useRef(null)

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (e.target.parentNode && !e.target.parentNode.matches('.sort-dropdown')) {
        setIsOpen(false)
      }
    })
  }, [sortRef])

  return (
    <div
      className={
        "relative p-4 bg-white shadow-xl rounded-full flex flex-col items-center"
      }
    >
      <button
        name="sort"
        id="sort-dropdown-button"
        onClick={() => setIsOpen(!isOpen)}
        className={`w-full flex justify-between items-center sort-dropdown`}
      >
        <Header2>Sort Results</Header2>
        <FontAwesomeIcon
          icon={faSort}
          className={"h-5 w-5 text-primary ml-2"}
        />
      </button>
      <div
        className={`${
          isOpen ? "visible" : "invisible"
        } w-52 flex flex-col z-40 bg-white absolute transform translate-y-10 shadow-2xl rounded-2xl sort-dropdown`}
        tabIndex={-1}
        id={'sort-dropdown-content'}
        ref={sortRef}
      >
        <button
          onClick={() => handleSubmit("newest")}
          id={"sort-newest-button"}
          disabled={sortValue === 'newest'}
          className={`h-10 w-full font-reg text-sm border-b border-gray hover:border-2 hover:border-primary text-almost-black ${sortValue === 'newest' ? 'text-light bg-primary hover:text-light' : 'bg-white'} rounded-t-2xl hover:rounded-2xl disabled:border-none sort-dropdown`}
        >
          Newest
        </button>
        <button
          onClick={() => handleSubmit("msrp_cents_asc")}
          id={"sort-lohi-button"}
          disabled={sortValue === 'msrp_cents_asc'}
          className={`h-10 w-full font-reg text-sm border-b border-gray hover:border-2 hover:border-primary text-almost-black ${sortValue === 'msrp_cents_asc' ? 'text-light bg-primary hover:text-light' : 'bg-white'} hover:rounded-2xl disabled:border-none sort-dropdown`}
        >
          Price: low to high
        </button>
        <button
          onClick={() => handleSubmit("msrp_cents_desc")}
          id={"sort-hilo-button"}
          disabled={sortValue === 'msrp_cents_desc'}
          className={`h-10 w-full font-reg text-sm border-b border-gray hover:border-2 hover:border-primary text-almost-black ${sortValue === 'msrp_cents_desc' ? 'text-light bg-primary hover:text-light' : 'bg-white'} rounded-b-2xl hover:rounded-2xl disabled:border-none sort-dropdown`}
        >
          Price: high to low
        </button>
      </div>
    </div>
  )
}

export default SortDropDown
