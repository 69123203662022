import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Header1 from "~/components/atoms/text/Header1"
import Header2 from '../../../atoms/text/Header2.tsx'
import ProductIndexItem from "../../../../components/dashboard/brand/molecules/ProductIndexItem.tsx"
import UploadProducts from "./UploadProducts.tsx"
import AddProductsDropdown from '../../../molecules/inputs/AddProductsDropdown.tsx'


const ManageProducts = () => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const [products, setProducts] = useState([])

  const loadProducts = async () => {
    axios.get('/products').then((r) => {
      setProducts(r.data)
    })
  }

  let productItems = () => {
    return products.map(p => {
      return <ProductIndexItem product={p}/>
    })
  }

  useEffect(() => {
    loadProducts()
  },[])

  useEffect(() => {
    console.log(">>>", products)
  }, [products])

  return(
    <React.Fragment>
      {
        !products?.length ? <UploadProducts /> :
        <section className={"w-2/3 flex flex-col"}>
          <header className={'flex justify-between'}>
            <Header1 styles={'mb-4'}>Manage Products</Header1>
            <AddProductsDropdown />
          </header>
          <section className={'w-full bg-transparent-white border border-gray mb-4'}>
            <header className={'h-12 flex items-center border-b border-gray px-3'}>
              <Header2>{`${products.length} Products`}</Header2>
            </header>
            {productItems()}
          </section>
          <AddProductsDropdown />
        </section>
      }
    </React.Fragment>
  )
}

export default ManageProducts

