import React from 'react'
import { Link } from 'react-router-dom'
import Paragraph1 from "~/components/atoms/text/Paragraph1";

const AlreadyHaveAnAccount = () => {
  return (
    <article className={'h-10 py-2 flex justify-center items-center'}>
      <Paragraph1>Already have an account?</Paragraph1>
      <Link to={'/sign_in'} className={'ml-2 text-dark text-sm font-reg underline underline-offset-1 text-link-blue'}>Sign in</Link>
    </article>
  )
}

export default AlreadyHaveAnAccount
