import React, { useState, useEffect, useContext } from 'react'
import FormatDollars from "../../utils/FormatDollars";
import FormatInt from '../../utils/FormatInt'
import Paragraph1 from "../../components/atoms/text/Paragraph1";
import CtaButton from "../../components/atoms/buttons/CtaButton";
import { Formik, Form } from 'formik'
import { OffersContext } from "../../contexts/Offers.js";
import axios from 'axios'
import { parseOffers } from "~/utils/handleOffers";

const CreateOfferForm = ({ product }) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const [quantityInputValue, setQuantityInputValue] = useState('0');
  const [internalQuantity, setInternalQuantity] = useState(0);
  const [offerPriceInputValue, setOfferPriceInputValue] = useState('$0.00');
  const [internalOfferPrice, setInternalOfferPrice] = useState(0);
  const [showQuantityError, setShowQuantityError] = useState("")
  const [showPriceError, setShowPriceError] = useState(false)

  const { offers, setOffers } = useContext(OffersContext)

  let handleQuantityInput = e => {
    let input = e.target.value;
    if (!isNaN(parseFloat(input))) {
      let value = input.split(',').join('');
      setInternalQuantity(value);
      setQuantityInputValue(FormatInt(value));
    } else {
      setQuantityInputValue('');
    }
  };

  let handleOfferPriceInput = e => {
    let input = e.target.value;
    let regex = /[^0-9]/g;
    let value = input.replace(regex, '');
    let numValue = parseFloat(value);
    if (!isNaN(numValue)) {
      setInternalOfferPrice(numValue);
      setOfferPriceInputValue(FormatDollars(numValue));
    }
  };

  let createOffer = () =>  {
    axios.post('/offers', {
      offer: {
        seller_id: product.seller_id,
        offer_products_attributes: [
          {
            product_id: product.id,
            quantity: internalQuantity,
            unit_price: internalOfferPrice
          }
        ]
      }
    }).then(() => {
      axios.get('/offers').then((res) => {
        setOffers(parseOffers(res.data))
      })
    })
  }

  useEffect(() => {
    if (product) {
      setInternalQuantity(product.units)
      setQuantityInputValue(FormatInt(product.units))
      setInternalOfferPrice(product.seller_price_cents)
      setOfferPriceInputValue(FormatDollars(product.seller_price_cents))
    }
  }, [product])

  useEffect(() => {
    if (internalQuantity > product?.units) {
      setShowQuantityError("Exceeds available units")
    } else if (internalQuantity < product?.min_order_units) {
      setShowQuantityError(`${FormatInt(product.min_order_units)} unit minimum`)
    } else {
      setShowQuantityError("")
    }
  }, [internalQuantity])

  useEffect(() => {
    if (internalOfferPrice > product?.seller_price_cents) {
      setShowPriceError(true)
    } else {
      setShowPriceError(false)
    }
  }, [internalOfferPrice])

  return (
    <div
      className={
        "my-1.5 py-3 w-full flex flex-col border-t border-b border-secondary"
      }
    >
      <div className={"w-full mb-3 flex flex-col items-center"}>
        <div
          className={
            "grid grid-cols-3 gap-x-2 gap-y-2 items-center justify-items-end"
          }
        >
          <Paragraph1 styles={"w-32 text-right"}>Quantity:</Paragraph1>
          <label htmlFor={"offer_units"} className={"flex w-32 place-content-center"}>
            <input
              type={"text"}
              name={"offer_units"}
              className={`w-24 bg-light-gray text-dark text-sm font-reg rounded-xl border-2 ${
                showQuantityError
                  ? "border-red-600 focus:border-2 focus:border-red-600"
                  : "border-dark"
              }`}
              value={quantityInputValue}
              onChange={handleQuantityInput}
            />
          </label>
          <Paragraph1
            styles={`text-red-600 w-32 ${!showQuantityError && "invisible"}`}
          >
            { showQuantityError }
          </Paragraph1>
          <Paragraph1 styles={"w-32 text-right"}>Unit offer price:</Paragraph1>
          <label htmlFor={"offer_price"} className={"flex w-32 place-content-center"}>
            <input
              type={"text"}
              name={"offer_price"}
              className={`w-24 bg-light-gray text-dark text-sm font-reg rounded-xl border-2 ${
                showPriceError
                  ? "border-red-600 focus:border-2 focus:border-red-600"
                  : "border-dark"
              }`}
              value={offerPriceInputValue}
              onChange={handleOfferPriceInput}
            />
          </label>
          <Paragraph1 styles={`text-red-600 w-32 ${!showPriceError && "invisible"}`}
          >
            Exceeds asking price
          </Paragraph1>
          <Paragraph1 styles={"w-32 text-right"}>Offer total:</Paragraph1>
          <Paragraph1 styles={"text-center w-32"}>
            {FormatDollars(internalQuantity * internalOfferPrice)}
          </Paragraph1>
        </div>
      </div>
      {/*<div className={'w-full h-10 flex flex-col place-content-center place-items-center'}>*/}
      {/*  </div>*/}
      <CtaButton
        id={"product-details-add-to-cart"}
        onclick={createOffer}
        disabled={showQuantityError || showPriceError}
      >
        Add to cart
      </CtaButton>
    </div>
  )
}

export default CreateOfferForm
