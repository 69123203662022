import React, { useState, useEffect, useContext } from 'react'
import FormatDollars from "../../utils/FormatDollars";
import Paragraph1 from "../atoms/text/Paragraph1";
import OrderProductRow from './OrderProductRow'
import axios from 'axios'
import { CurrentUserContext } from "~/contexts/CurrentUser"
import CtaButton from "~/components/atoms/buttons/CtaButton";


const Order = ({ order }) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const { currentUser } = useContext(CurrentUserContext)
  const [date, setDate] = useState("")
  const [counterOrder, setCounterOrder] = useState(false)

  const STATUS = {
    awaiting_initial_payment: "Awaiting initial payment",
    payment_pending: "Initial payment submitted",
    awaiting_shipment: "Awaiting shipment",
    shipped: "Shipped",
    received: "Received",
    paid_in_full: "Paid in full",
    completed: "Completed",
    canceled: "Canceled",
    requires_payment_action: "Payment requires action"
  }

  const checkOut = () => {
    axios.post(`/purchase_orders/${order?.id}/payment`).then(res => {
      window.location.href = res.data.url
    })
  }

  const verifyPayment = () => {
    axios.get(`/purchase_orders/${order?.id}/verify_payment`).then(res => {
      window.location.href = res.data.url
    })
  }

  useEffect(() => {
    let tStamp = order?.updated_at
    let parsedDate = new Date(tStamp).toLocaleString('en-US', {year: "numeric", month: "long", day: "numeric"})
    setDate(parsedDate)
  }, [order])

  return (
    <article className={'my-2 border border-secondary rounded-xl'}>
      <header className={'p-2 grid grid-cols-5 bg-light-gray rounded-t-xl'}>
        <div className={'col-span-1 bg-primary bg-opacity-10 rounded-md text-center'}>
          <p className={'text-dark text-xl font-reg uppercase'}>{STATUS[order?.status]}</p>
          <Paragraph1>{date}</Paragraph1>
        </div>
        <div className={'col-span-1 place-items-center'}>
          <Paragraph1 styles={'text-center uppercase'}>{ currentUser?.brand ? 'Buyer' : 'Seller' }</Paragraph1>
          <Paragraph1 styles={'text-center'}>{ currentUser?.brand ? order?.buyer_name : order?.seller_name }</Paragraph1>
        </div>
        <div className={'col-span-1 place-items-center'}>
          <Paragraph1 styles={'text-center uppercase'}>Items</Paragraph1>
          <Paragraph1 styles={'col-span-1 text-center'}>{order?.sku_count}</Paragraph1>
        </div>
        <div className={'col-span-1 place-items-center'}>
          <Paragraph1 styles={'text-center uppercase'}>Total</Paragraph1>
          <Paragraph1 styles={'col-span-1 text-center'}>{FormatDollars(order?.total_cents)}</Paragraph1>
        </div>
        {
          currentUser?.brand &&
          <div className={'col-span-1 place-items-center'}>
            <Paragraph1 styles={'text-center uppercase'}>Net after MMBI fee</Paragraph1>
            <Paragraph1 styles={'col-span-1 text-center'}>{FormatDollars(order?.total_net_to_seller)}</Paragraph1>
          </div>
        }
        {
          currentUser?.retailer && order?.status === 'awaiting_initial_payment' &&
          <CtaButton onclick={checkOut} id={'buyer-dash-check-out'}>Check out</CtaButton>
        }
        {
          currentUser?.retailer && order?.status === 'requires_payment_action' &&
          <CtaButton onclick={verifyPayment} id={'buyer-dash-check-out'}>Verify Payment</CtaButton>
        }
      </header>

      <section className={'p-2'}>
        {
          order?.purchase_order_products.map((op, i) => {
            return  <div className={`${(i % 2 === 0) ? 'bg-light' : 'bg-light-gray'} p-1.5 flex justify-between`}>
              <OrderProductRow orderProduct={op} orderId={order?.id} state={order?.state} counterOrder={counterOrder} setCounterOrder={setCounterOrder} order={order}/>
            </div>
          })
        }
      </section>
    </article>
  )
}

export default Order
