import React from "react"
import Header3 from "../../atoms/text/Header3.tsx"
import Overline from "../../atoms/text/Overline.tsx"
import Header1 from "../../atoms/text/Header1.tsx"
import CtaLink from '../../atoms/buttons/CtaLink.tsx'
import SecondaryCtaLink from "~/components/atoms/buttons/SecondaryCtaLink";

const SignUpModal = ({ isOpen, setIsOpen }) => {

  return (
    <React.Fragment >
      <section className={'h-full w-full p-1 xs:p-2 sm:p-4 flex flex-col items-center'}>
        <section className={'w-full flex flex-col justify-center items-center'}>
          <Overline styles={'mt-1 sm:mt-4'}>Join</Overline>
          <Header1 styles={'text-center mb-6'}>Join our private marketplace connecting the world's best brands to a new group of buyers.</Header1>
          <div className={'w-full flex flex-col items-center mb-4'}>
            <Header3 styles={'text-center mb-2'}>Are you a buyer?</Header3>
            <div className={'w-60'}>
              <CtaLink id={'sign-up-modal-retailer-link'} link={'/retailer_inquiry'}>Sign Up to Buy</CtaLink>
            </div>
          </div>
          <div className={'w-full flex flex-col items-center'}>
            <Header3 styles={'text-center mb-2'}>Are you a seller?</Header3>
            <div className={'w-60'}>
              <CtaLink id={'sign-up-modal-brand-link'} link={'/brand_inquiry'}>Sell on MadeMeBuyIt</CtaLink>
            </div>
          </div>
        </section>
          <div className={'w-full mt-8 border-t-2 border-almost-black border-dashed'}>&nbsp;</div>
        <Header3 styles={'mb-2'}>Already have an account?</Header3>
        <div className={'w-60'}>
          <SecondaryCtaLink id={'sign-up-modal-sign-in-button'} link={'/sign_in'} >
            Sign in
          </SecondaryCtaLink>
        </div>
      </section>
    </React.Fragment>
  )
}

export default SignUpModal

