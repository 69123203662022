import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import {
  faSquarePen,
  faTrashCan,
  faSquareCheck,
  faCartArrowDown,
  faCartShopping
} from "@fortawesome/free-solid-svg-icons";
import FormatInt from "../utils/FormatInt";
import FormatDollars from "../utils/FormatDollars";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OffersContext } from "../contexts/Offers.js";
import axios from 'axios'
import { parseOffers } from '../utils/handleOffers.js'
import { CurrentEditContext } from '../contexts/CurrentEdit.js'

const CartOfferProductRow = ({ offerProduct, offerId, state }) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const [product, setProduct] = useState()
  const [quantityInputValue, setQuantityInputValue] = useState('0');
  const [internalQuantity, setInternalQuantity] = useState(0);
  const [offerPriceInputValue, setOfferPriceInputValue] = useState('$0.00');
  const [internalOfferPrice, setInternalOfferPrice] = useState(0);
  const [showQuantityError, setShowQuantityError] = useState(null)
  const [showPriceError, setShowPriceError] = useState(false)
  const [editing, setEditing] = useState(false)
  const { offers, setOffers } = useContext(OffersContext)
  const { currentEdit, setCurrentEdit } = useContext(CurrentEditContext)
  const [editable, setEditable] = useState(true)

  let handleQuantityInput = e => {
    let input = e.target.value;
    if (!isNaN(parseFloat(input))) {
      let value = input.split(',').join('');
      setInternalQuantity(value);
      setQuantityInputValue(FormatInt(value));
    } else {
      setQuantityInputValue('');
    }
  };

  let handleOfferPriceInput = e => {
    let input = e.target.value;
    let regex = /[^0-9]/g;
    let value = input.replace(regex, '');
    let numValue = parseFloat(value);
    if (!isNaN(numValue)) {
      setInternalOfferPrice(numValue);
      setOfferPriceInputValue(FormatDollars(numValue));
    }
  };

  let handleRemove = () => {
    axios.delete(`/offers/${offerId}/offer_products/${offerProduct.id}`).then((res) => {
      axios.get('/offers').then((response) => {
        setOffers(parseOffers(response.data))
      })
    })
  }

  let handleSaveForLater = () => {
    axios.post(`/offers/${offerId}/save_for_later`, {
      offer_product_id: offerProduct.id,
    }).then((res) => {
      axios.get('/offers').then((response) => {
        setOffers(parseOffers(response.data))
      })
    })
  }

  let saveProduct = () => {
    axios.put(`/offers/${offerId}/offer_products/${offerProduct.id}`, {
        quantity: internalQuantity,
        unit_price: internalOfferPrice
    }).then((res) => {
      axios.get('/offers').then((response) => {
        setOffers(parseOffers(response.data))
      })
    }).then(() => {
      setCurrentEdit("")
    })
  }

  let handleMoveToCart = () => {
    axios.post(`/offers/${offerId}/move_to_cart`, {
      offer_product_id: offerProduct.id,
    }).then((res) => {
      axios.get('/offers').then((response) => {
        setOffers(parseOffers(response.data))
      })
    })
  }

  let editProduct = () => {
    editable && setCurrentEdit(offerProduct.id)
  }

  useEffect(() => {
    setEditable(!currentEdit || currentEdit === offerProduct.id)
    setEditing(currentEdit === offerProduct.id)
  }, [currentEdit])

  useEffect(() => {
    setProduct(offerProduct.attributes.product)
    if (offerProduct) {
      setInternalQuantity(offerProduct.attributes.quantity)
      setQuantityInputValue(FormatInt(offerProduct.attributes.quantity))
      setInternalOfferPrice(offerProduct.attributes.unit_price)
      setOfferPriceInputValue(FormatDollars(offerProduct.attributes.unit_price))
    }
  }, [offerProduct])

  useEffect(() => {
    if (internalQuantity > product?.units) {
      setShowQuantityError("Exceeds available units")
    } else if (internalQuantity < product?.min_order_units) {
      setShowQuantityError(`${FormatInt(product?.min_order_units)} unit minimum`)
    } else {
      setShowQuantityError(null)
    }
  }, [internalQuantity])

  useEffect(() => {
    if (internalOfferPrice > product?.seller_price_cents) {
      setShowPriceError(true)
    } else {
      setShowPriceError(false)
    }
  }, [internalOfferPrice])

  return (
    <div
      className={`my-4 p-2 border ${editing ? 'border-primary shadow-2xl' : 'border-gray'} ${editable ? 'bg-light' : 'bg-light-gray'} rounded-xl flex justify-between`}
    >
      <Link to={`/all_products/${offerProduct.attributes.product?.brand_id}/${offerProduct.attributes.product?.id}`} className={"flex w-60"}>
        <img src={offerProduct?.attributes.images[0]} className={"h-16 w-16"} />
        <p className={"font-reg text-lg text-dark ml-2"}>{product?.name}</p>
      </Link>
      <div className={"w-7/12 grid grid-cols-11"}>
        <label
          htmlFor={"offer_units"}
          className={
            `text-xs ${showQuantityError ? 'text-red-600' : 'text-dark'} font-light flex flex-col justify-start items-center col-span-3 text-center`
          }
        >
          { FormatInt(product?.units) + " available"}
          <input
            type={"text"}
            value={quantityInputValue}
            onChange={handleQuantityInput}
            className={`rounded-lg text-center border ${showQuantityError ? 'border-red-600' : 'border-secondary'} ${editable ? 'bg-light' : 'bg-light-gray border-light-gray disabled:border-light-gray'} disabled:border-light`}
            disabled={!editing}
          />
          { showQuantityError || "units"}
        </label>
        <p className={"text-sm font-dark font-light grid place-content-center"}>X</p>
        <label
          htmlFor={"offer_price"}
          className={
            `text-xs ${showPriceError ? 'text-red-600' : 'text-dark'} font-light flex flex-col justify-start items-center col-span-3 text-center`
          }
        >
          { FormatDollars(product?.seller_price_cents) + " asking price"}
          <input
            type={"text"}
            value={offerPriceInputValue}
            onChange={handleOfferPriceInput}
            className={`rounded-lg text-center border ${showPriceError ? 'border-red-600' : 'border-secondary'} ${editable ? 'bg-light' : 'bg-light-gray border-light-gray disabled:border-light-gray'} disabled:border-light`}
            disabled={!editing}
          />
          { showPriceError ? "Exceeds asking price" : "per unit"}
        </label>
        <p className={"text-sm font-dark font-light grid place-content-center"}>=</p>
        <div className={'text-dark font-light flex flex-col justify-end items-center col-span-3'}>
          <p className={'h-10 p-2 text-center'}>{FormatDollars(internalQuantity * internalOfferPrice)}</p>
          <p className={'text-xs'}>total</p>
        </div>
      </div>
      <div className={"flex flex-col justify-between items-end"}>
        {
          editing ?
            <button className={"font-reg text-sm text-dark hover:underline disabled:text-gray disabled:hover:no-underline"} onClick={saveProduct} disabled={!!showPriceError || showQuantityError} >
              Save
              <FontAwesomeIcon icon={faSquareCheck} className={"h-4 w-4 ml-2"} />
            </button>
            :
            <button className={"font-reg text-sm text-dark hover:underline disabled:text-gray disabled:hover:no-underline"} onClick={editProduct} disabled={!editable} >
              Edit
              <FontAwesomeIcon icon={faSquarePen} className={"h-4 w-4 ml-2"} />
            </button>
        }
        {
          state === 'draft' ?
            <button className={"font-reg text-sm text-dark hover:underline disabled:text-gray disabled:hover:no-underline"} onClick={handleSaveForLater} disabled={editing || !editable}>
              Save for later
              <FontAwesomeIcon icon={faCartArrowDown} className={"h-4 w-4 ml-2"} />
            </button>
            : state === 'saved_for_later' ?
              <button className={"font-reg text-sm text-dark hover:underline disabled:text-gray disabled:hover:no-underline"} onClick={handleMoveToCart} disabled={editing || !editable}>
                Move to Cart
                <FontAwesomeIcon icon={faCartShopping} className={"h-4 w-4 ml-2"} />
              </button>
            : null
        }
        <button className={"font-reg text-sm text-dark hover:underline disabled:text-gray disabled:hover:no-underline"} onClick={handleRemove} disabled={editing || !editable}>
          Remove
          <FontAwesomeIcon icon={faTrashCan} className={"h-4 w-4 ml-2"} />
        </button>
      </div>
    </div>
  )
}

export default CartOfferProductRow
