import React, { useState, useEffect, useContext } from 'react'
import Header2 from "../atoms/text/Header2";
import FormatInt from "../../utils/FormatInt";
import FormatDollars from "../../utils/FormatDollars";
import Paragraph1 from "../atoms/text/Paragraph1";
import { CurrentUserContext } from "../../contexts/CurrentUser"
import { OffersContext } from '../../contexts/Offers.js'



const CounterOfferModal = ({ offer, offerProduct, offerProductTotals, setOfferProductTotals, counterOfferProducts, setCounterOfferProducts }) => {
  const [showPriceError, setShowPriceError] = useState(false)
  const [offerPriceInputValue, setOfferPriceInputValue] = useState("")
  const [internalOfferPrice, setInternalOfferPrice] = useState("")
  const { currentUser } = useContext(CurrentUserContext)
  let { offers, setOffers } = useContext(OffersContext)

  let handleOfferPriceInput = e => {
    let input = e.target.value;
    let regex = /[^0-9]/g;
    let value = input.replace(regex, '');
    let numValue = parseFloat(value);
    if (!isNaN(numValue)) {
      setInternalOfferPrice(numValue);
      setOfferPriceInputValue(FormatDollars(numValue));
    }
  };

  useEffect(() => {
    console.log("Value", internalOfferPrice)
    if (currentUser?.retailer && internalOfferPrice > offerProduct?.attributes.unit_price) {
      setShowPriceError(true)
    } else if (currentUser?.brand && internalOfferPrice < offerProduct?.attributes.unit_price) {
      setShowPriceError(true)
    } else {
      setShowPriceError(false)
    }

    setCounterOfferProducts(counterOfferProducts.map((cop) => {
      if (cop.id === offerProduct.id) {
        return {
          ...cop,
          attributes: {
            ...cop.attributes,
            unit_price: internalOfferPrice
          }
        }
      } else {
        return cop
      }
    }))

  }, [internalOfferPrice])

  useEffect(() => {
    let total = internalOfferPrice * offerProduct.attributes.quantity
    let newValue = {
      ...offerProductTotals,
      [offerProduct.id]: total
    }
    setOfferProductTotals(newValue)
  }, [internalOfferPrice])


  useEffect(() => {
    offerProduct && setOfferPriceInputValue(FormatDollars(offerProduct.attributes.unit_price))
    offerProduct && setInternalOfferPrice(offerProduct.attributes.unit_price)
  }, [offerProduct])

  return (
    <React.Fragment>
      <p className={"text-dark text-xs font-light"}>
        {offerProduct.attributes.product.upc}
      </p>
      <p className={"text-dark text-base font-reg"}>
        {offerProduct.attributes.product.name}
      </p>
      <div className={"flex"}>
        <p className={"text-dark text-sm font-reg"}>
          {FormatInt(offerProduct.attributes.quantity)}
        </p>
        <p className={"text-dark text-sm font-reg mx-1"}>units X</p>
        <p className={"text-dark text-sm font-reg"}>
          {FormatDollars(offerProduct.attributes.unit_price)}
        </p>
        <p className={"text-dark text-sm font-reg mx-1"}>per unit</p>
        <p className={"text-dark text-sm font-reg mr-1"}>=</p>
        <p className={"text-dark text-sm font-reg"}>
          {FormatDollars(offerProduct.attributes.total_price)}
        </p>
      </div>
      <div className={"flex items-center bg-white my-1 py-2 border-y border-secondary"}>
        <p className={"text-dark text-sm font-reg mx-1"}>Counter price: </p>

        <label htmlFor={"counter_price"} className={"flex w-fit place-content-center place-items-center"}>
          <input
            type={"text"}
            name={"counter_price"}
            className={`w-24 h-8 rounded-xl text-dark text-sm font-reg ${
              showPriceError ? "border-red-600 focus:border-2 focus:border-red-600" : "border-dark"
            }`}
            value={offerPriceInputValue}
            onChange={handleOfferPriceInput}
          />
          <p className={"text-dark text-sm font-reg mx-1"}>per unit</p>
        </label>

        <p className={"text-dark text-sm font-reg mr-1"}>=</p>
        <p className={"text-dark text-sm font-reg"}>
          {FormatDollars(offerProduct.attributes.quantity * internalOfferPrice)}
        </p>
        <Paragraph1 styles={`text-red-600 w-32 ml-4 ${!showPriceError && "invisible"}`}
        >
          { currentUser?.retailer ? "Above seller offer" : "Below buyer offer"}
        </Paragraph1>
      </div>
    </React.Fragment>
  )
}

export default CounterOfferModal
