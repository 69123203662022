import React, { useState, useEffect, useContext } from 'react'
import FormatDollars from "../../utils/FormatDollars";
import Paragraph1 from "../atoms/text/Paragraph1";
import OfferProductRow from './OfferProductRow'
import CtaButton from "../atoms/buttons/CtaButton";
import CtaLink from "../atoms/buttons/CtaLink";
import { CurrentEditContext } from "~/contexts/CurrentEdit";
import {parseOffers} from "~/utils/handleOffers";
import axios from 'axios'
import { OffersContext } from '~/contexts/Offers'
import { OrdersContext } from '~/contexts/Orders'
import Modal from './BigModal'
import Header2 from '../atoms/text/Header2'
import CounterOfferModal from './CounterOfferModal.jsx'
import { CurrentUserContext } from "~/contexts/CurrentUser"
import {useNavigate} from "react-router-dom";
import MessageSlider from "~/components/molecules/MessageSlider";


const Offer = ({ offer }) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  let { offers, setOffers } = useContext(OffersContext)
  let { orders, setOrders } = useContext(OrdersContext)
  const { currentUser } = useContext(CurrentUserContext)
  const [date, setDate] = useState("")
  const [currentEdit, setCurrentEdit] = useState("")
  const [counterOffer, setCounterOffer] = useState(false)
  const [offerEdited, setOfferEdited] = useState(false)
  const [offerProductTotals, setOfferProductTotals] = useState(0)
  const [newTotal, setNewTotal] = useState(0)
  const [counterModalOpen, setCounterModalOpen] = useState(false)
  const [counterOfferProducts, setCounterOfferProducts] = useState([])
  const navigate = useNavigate()
  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState("")
  const [messageIsSuccess, setMessageIsSuccess] = useState(true)

  const STATUS = {
    draft: 'Draft',
    saved_for_later: 'Saved for later',
    seller_review: currentUser?.brand ? 'Ready for review' : 'Submitted to seller',
    buyer_review: currentUser?.retailer ? 'Ready for review' : 'Submitted to buyer',
    accepted: 'Accepted',
    rejected: 'Rejected'
  }

  const submitCounterOffer = () => {
    Promise.all(counterOfferProducts.map((cop) => {
      return axios.put(`/offers/${offer.id}/offer_products/${cop.id}`, {
        unit_price: cop.attributes.unit_price
      })
    })).then(() => {
      axios.put(`/offers/${offer.id}`, {
        state: currentUser?.brand ? 'buyer_review' : 'seller_review'
      }).then(() => {
        axios.get('/offers').then((res) => {
          setOffers(parseOffers(res.data))
          setCounterModalOpen(false)
        })
      })
    })
  }

  const parseOrders = (unparsedOrders) => {
    let parsedOrders = []
    let purchase_order_products = unparsedOrders.included

    unparsedOrders.data.forEach((o) => {
      let obj = o.attributes
      obj.purchase_order_products = o.relationships.purchase_order_products.data.map((elem) => {
        return purchase_order_products.find(op => op.id === elem.id)
      })
      parsedOrders.push(obj)
    })

    return parsedOrders.sort((a, b) => {
      return a.created_at - b.created_at
    })
  }

  const handleAccept = () => {
    axios.put(`/offers/${offer.id}`, {
      state: 'accepted'
    }).then((updateRes) => {
      axios.get('/offers').then((res) => {
        setOffers(parseOffers(res.data))
      }).then(() => {
        axios.get('/marketplace/purchase_orders').then(res => {
          setOrders(parseOrders(res.data))
        }).then(() => {
          navigate('/my_orders')
        })
      })
    }).catch((err) => {
      console.log(">>>", err.response.data.errors)
      if (err.response.data.errors == "Validation failed: Units must be greater than or equal to 0") {
        handleError("Available quantities have changed.")
      }
    })
    console.log("Begin Stripe Checkout")
    setCounterOffer(false)
  }

  const handleError = (m) => {
    setShowMessage(true)
    setMessage(m)
    setMessageIsSuccess(false)
  }

  useEffect(() => {
    let tStamp = offer?.history[offer?.history.length - 1][0].timestamp
    let parsedDate = new Date(tStamp).toLocaleString('en-US', {year: "numeric", month: "long", day: "numeric"})
    setDate(parsedDate)

    if (offer) {
      let initialValue = offer.offer_products.reduce((accu, el) => {
        accu[el.id] = el.attributes.total_price
        return accu
      }, {})
      setOfferProductTotals(initialValue)
      setCounterOfferProducts(offer.offer_products)
    }
  }, [offer])

  useEffect(() => {
    let total = FormatDollars(Object.values(offerProductTotals).reduce((accu, el) => { accu+= el; return accu }, 0))
    setNewTotal(total)
  }, [offerProductTotals])

  return (
    <CurrentEditContext.Provider value={{ currentEdit, setCurrentEdit }} >
      <article className={'my-2 border border-secondary rounded-xl'}>
        <header className={'p-2 grid grid-cols-5 bg-light-gray rounded-t-xl'}>
          <div className={'col-span-1'}>
            <Paragraph1 styles={'uppercase'}>{STATUS[offer?.state]}</Paragraph1>
            <Paragraph1>{date}</Paragraph1>
          </div>
          <div className={'col-span-1 place-items-center'}>
            <Paragraph1 styles={'text-center uppercase'}>{ currentUser?.brand ? 'Buyer' : 'Seller' }</Paragraph1>
            <Paragraph1 styles={'text-center'}>{ currentUser?.brand ? offer?.buyer_name : offer?.seller_name }</Paragraph1>
          </div>
          <div className={'col-span-1 place-items-center'}>
            <Paragraph1 styles={'text-center uppercase'}>Items</Paragraph1>
            <Paragraph1 styles={'col-span-1 text-center'}>{offer?.sku_count}</Paragraph1>
          </div>
          <div className={'col-span-1 place-items-center'}>
            <Paragraph1 styles={'text-center uppercase'}>Total</Paragraph1>
            <Paragraph1 styles={'col-span-1 text-center'}>{FormatDollars(offer?.total_cents)}</Paragraph1>
          </div>
          {
            currentUser?.brand &&
            <div className={'col-span-1 place-items-center'}>
              <Paragraph1 styles={'text-center uppercase'}>Net after MMBI fee</Paragraph1>
              <Paragraph1 styles={'col-span-1 text-center'}>{FormatDollars(offer?.total_net_to_seller)}</Paragraph1>
            </div>
          }
        </header>
        {
          ((currentUser?.brand && offer?.state === 'seller_review') || (currentUser?.retailer && offer?.state === 'buyer_review')) &&
          <section className={'bg-light border-b border-light-gray p-1 mb-2 flex justify-between'}>
            <div className={'w-1/5'}>
              <CtaButton id={'buyer-dash-offer-counter'} onclick={() => setCounterModalOpen(true)} styles={"text-xl"}>Counter</CtaButton>
            </div>
            <div className={'w-1/5'}>
              <CtaButton id={'buyer-dash-offer-accept'} styles={"text-xl"} onclick={handleAccept} disabled={offerEdited} >Accept</CtaButton>
            </div>
          </section>
        }
        <section className={'p-2'}>
          {
            offer?.offer_products.map((op, i) => {
              return  <div className={`${(i % 2 === 0) ? 'bg-light' : 'bg-light-gray'} p-1.5 flex justify-between`}>
                        <OfferProductRow offerProduct={op} offerId={offer?.id} state={offer?.state} counterOffer={counterOffer} setCounterOffer={setCounterOffer} offer={offer}/>
                      </div>
            })
          }
        </section>
        <Modal isOpen={counterModalOpen} setIsOpen={setCounterModalOpen} width={'w-[720px]'}>
          <Header2>Counter Offer</Header2>
          {
            offer?.offer_products.map((op, i) => {
              return  <article className={`${(i % 2 === 0) ? 'bg-light-gray' : 'bg-light'} w-full p-2`}>
                        <CounterOfferModal offer={offer} offerProduct={op} offerProductTotals={offerProductTotals} setOfferProductTotals={setOfferProductTotals} counterOfferProducts={counterOfferProducts} setCounterOfferProducts={setCounterOfferProducts} />
                      </article>
            })
          }
          <p className={'text-dark text-xl font-med mt-2'}>{`Previous Total: ${FormatDollars(offer.total_cents)}`}</p>
          <p className={'text-dark text-xl font-med mb-2'}>{"New Total: " + newTotal}</p>
          <CtaButton id={'seller-counter-offer-modal-submit'} onclick={submitCounterOffer}>Submit Counter</CtaButton>
        </Modal>
      </article>
      <MessageSlider showMessage={showMessage} setShowMessage={setShowMessage} message={message} setMessage={setMessage} success={messageIsSuccess} />
    </CurrentEditContext.Provider>
  )
}

export default Offer
