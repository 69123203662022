import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Header1 from '../components/atoms/text/Header1.tsx'
import Header2 from '../components/atoms/text/Header2.tsx'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faDownload, faUpload } from '@fortawesome/free-solid-svg-icons'
import CtaButton from './atoms/buttons/CtaButton.tsx'
import CtaLink from './atoms/buttons/CtaLink.tsx'
import Overline from "~/components/atoms/text/Overline";
import Paragraph1 from "../components/atoms/text/Paragraph1.tsx"
import SmallModal from './organisms/modals/SmallModal'
import CsvTemplateTable from "../components/molecules/CsvTemplateTable"
import CsvTroubleshooting from "../components/molecules/CsvTroubleshooting"

export default function CsvImport() {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  let [uploaded, setUploaded] = useState(false)
  let [uploadedKey, setUploadedKey] = useState(null)
  let [isLoading, setLoading] = useState(false)
  let [isImportingProducts, setImportingProducts] = useState(false)
  let navigate = useNavigate()
  let [successModalOpen, setSuccessModalOpen] = useState(false)
  let [errorModalOpen, setErrorModalOpen] = useState(false)
  let [fileName, setFileName] = useState(null)
  let [totalImported, setTotalImported] = useState(0)
  let [numIssues, setNumIssues] = useState(0)
  let [numSuccessful, setNumSuccessful] = useState(0)
  let [fileError, setFileError] = useState('')
  const csvTipsRef = useRef(null)

  const closeSuccessModal = () => {
    setSuccessModalOpen(false)
    navigate('/dashboard/manage_products')
  }

  useEffect(() => {
    uploaded && axios.get(`/imported_csvs/${uploadedKey}`).then((res) => {
      setLoading(false)
    })
  }, [uploaded])

  const importCsv = (e) => {
    setImportingProducts(true)
    axios.post("/imported_csvs/", {key: uploadedKey}).then((res) => {
      setTotalImported(res.data.total_imported)
      setNumIssues(res.data.num_issues)
      setNumSuccessful(res.data.num_successful)
      setImportingProducts(false)
      setSuccessModalOpen(true)
    }).catch((err) => {
      setImportingProducts(false)
      setErrorModalOpen(true)
    })
  }

  const handleFileInput = (e) => {
    setFileName('')
    if (e.target.files[0].size < 4e+6) {
      setFileError('')
      uploadFile(e.target.files[0])
    } else {
      setFileError('File is too large. Maximum file size is 4 MB.')
    }
  }

  const uploadFile = async (f) => {
    setFileName(f.name)
    setLoading(true)
    setUploaded(false)
    axios.post("/presigned_urls").then((r) => {
      let fileKey = r.data.file_key
      axios.put(r.data.aws_url, f).then((res) => {
        setUploadedKey(fileKey)
        setUploaded(true)
        setLoading(false)
      })
    })
  }

  const fileInput = React.useRef()

  const handleClick = () => {
    fileInput.current.click()
  }

  let [tipsIsOpen, setTipsOpen] = useState(false)

  const handleCloseErrorModal = () => {
    setErrorModalOpen(false)
    setSuccessModalOpen(false)
    setTipsOpen(true)
  }

  useEffect(() => {
    if (tipsIsOpen) {
      csvTipsRef.current.scrollIntoView({ behavior: "smooth", block: "start"})
    }
  }, [tipsIsOpen])

  return (
    <section className={"w-2/3 flex flex-col"}>
      <header className={'flex justify-between'}>
        <Header1 styles={'mb-4'}>Add products from .csv</Header1>
      </header>

      <section className={'mb-6'}>
        <Paragraph1>Questions? Chat with us on Whatsapp at <span className={'text-red-600 font-bold'}>+1 (XXX) XXX-XXXX</span>.</Paragraph1>
      </section>

      <section className={'mb-6'}>
        <Header2 styles={'mb-4'}>1. Download our .csv template</Header2>
        <div className={'w-full'}>
          <a href={'/MadeMeBuyIt-import_example.csv'} className={'block mx-auto w-1/2'}>
            <p className={'w-full border-2 border-primary rounded-2xl bg-white h-20 font-light text-dark flex items-center justify-center cursor-pointer'}>
              <FontAwesomeIcon icon={faDownload} className={'mr-2 text-primary'} />
              Download
            </p>
          </a>
        </div>
      </section>

      <section className={'mb-6'}>
        <Header2 styles={'mb-2'}>2. Use template as a reference</Header2>
        <div className={'w-11/12 mx-auto mb-4 p-2'}>
          <Paragraph1>The following fields are used to create your products on MadeMeBuyIt. Please feel free to include any additional fields such as tags, barcodes, or other product metadata. These may be used to enhance search and discovery functionality for matching retailers with your products in future releases.</Paragraph1>
        </div>
        <CsvTemplateTable />
        <div ref={csvTipsRef}>
          <CsvTroubleshooting isOpen={tipsIsOpen} setOpen={setTipsOpen} />
        </div>
      </section>

      <section className={'mb-6'}>
        <Header2 styles={'mb-2'}>3. Upload completed file</Header2>
        <div className={'w-full flex flex-col justify-center items-center'}>
          <button onClick={handleClick} className={`w-1/2 border-2 border-primary rounded-2xl bg-white h-20 flex items-center justify-center cursor-pointer`} >
            {
              isLoading ? <FontAwesomeIcon icon={faSpinner} spinPulse className={'mr-2 text-primary'} /> : <FontAwesomeIcon icon={faUpload} className={'mr-2 text-primary'} />
            }
            <p className='text-base font-light text-dark'>{ fileName || 'Choose .csv file'}</p>
            <input type="file" ref={fileInput} className={'hidden'} id={'csv-input'} onChange={handleFileInput} />
          </button>
          <Paragraph1 styles={'mt-2 text-red-600'}>{ fileError }</Paragraph1>
        </div>
      </section>

      <section className={'mb-6'}>
        <Header2 styles={'mb-2'}>4. Import products from file</Header2>
          <div className={'w-full flex justify-center mt-6'}>
            <button
              className={'font-light text-xl rounded-full bg-primary h-12 w-80 disabled:bg-inherit'}
              onClick={importCsv}
              id={'import-csv-button'}
              disabled={!uploaded || isImportingProducts}
            >
              {
                isImportingProducts ?
                  <div className={`h-full w-full flex items-center justify-center border-2 rounded-full ${!uploaded || isImportingProducts ? 'bg-light-gray border-light-gray text-secondary' : 'bg-primary border-primary text-light hover:transition-all hover:-translate-x-px hover:translate-y-px hover:bg-primary-light hover:border-primary-light'} translate-x-px transition-all `} >
                    <FontAwesomeIcon icon={faSpinner} spinPulse className={`text-primary inline mr-2`} />
                    <Paragraph1 styles={'inline'}>Importing...</Paragraph1>
                  </div>
                  :
                  <div className={`h-full w-full flex items-center justify-center border-2 rounded-full ${!uploaded || isImportingProducts ? 'bg-light-gray border-light-gray text-secondary' : 'bg-primary border-primary text-light hover:transition-all hover:-translate-x-px hover:translate-y-px hover:bg-primary-light hover:border-primary-light'} translate-x-px transition-all `} >
                    Begin import
                  </div>
              }
            </button>
          </div>
      </section>

      {
        isImportingProducts &&
        <React.Fragment>
          <Paragraph1 styles={'w-full text-center'}>Import may take a few minutes.</Paragraph1>
        </React.Fragment>
      }

        <SmallModal isOpen={successModalOpen} setIsOpen={closeSuccessModal} >
          <div className={'h-full w-full flex flex-col items-center'}>
            {
              numIssues ?
                <React.Fragment>
                  <Overline styles={'mt-2'}>Imported with issues</Overline>
                  <Header2 styles={'mt-2'}>{`${numSuccessful} product${numSuccessful == 1 ? '' : 's'} imported successfully`}</Header2>
                  <Header1 styles={'my-2 text-red-600'}>{`${numIssues} product${numIssues == 1 ? '' : 's'} require${numIssues == 1 ? 's' : ''} attention`}</Header1>
                  <Header2 styles={'text-center mb-4'}>Please address issues by editing the products on your My Products page.</Header2>
                  <div className={'w-80'}>
                    <CtaLink id={'import-success-modal-products-link'} link={'/dashboard/manage_products'}>Go to My Products</CtaLink>
                  </div>
                  <Paragraph1 styles={'text-xs mt-2'}>*Fewer than you expected? Ensure each product has a unique upc.</Paragraph1>
                </React.Fragment>
                : !numSuccessful ?
                <React.Fragment>
                  <Overline styles={'mt-2'}>Error</Overline>
                  <Header1 styles={'my-4 text-red-600'}>{`0 products imported`}</Header1>
                  <Header2 styles={'text-center mb-4'}>Unique UPCs are required for the file to be read correctly. Please check your file, check out our tips section, and try again.</Header2>
                  <div className={'w-80'}>
                    <CtaButton id={'import-success-modal-ok-button'} onclick={handleCloseErrorModal} >Ok</CtaButton>
                  </div>
                </React.Fragment>
                :
                <React.Fragment>
                  <Overline styles={'mt-2'}>Success!</Overline>
                  <Header1 styles={'mt-2'}>{`${numSuccessful} product${numSuccessful == 1 ? '' : 's'}`}</Header1>
                  <Header1>imported successfully</Header1>
                  <Header2 styles={'text-center my-4'}>These can now be discovered by national retailers!</Header2>
                  <div className={'w-80'}>
                    <CtaLink id={'import-success-modal-products-link'} link={'/dashboard/manage_products'}>Go to My Products</CtaLink>
                  </div>
                  <Paragraph1 styles={'text-xs mt-4'}>*Fewer than you expected? Ensure each product has a unique upc.</Paragraph1>
                </React.Fragment>
            }
          </div>
        </SmallModal>

        <SmallModal isOpen={errorModalOpen} setIsOpen={setErrorModalOpen} >
          <div className={'h-full w-full flex flex-col items-center'}>
            <Overline styles={'mt-2'}>Error</Overline>
            <div className='text-center'>
              <Header1 styles={'mt-6'}>There was a problem uploading your file.</Header1>
              <Paragraph1 styles={'mt-2'}>Make sure your file is formatted according to the template, check our tips section, and try again.</Paragraph1>
            </div>
            <div className='w-80 mt-6 cursor-pointer' onClick={handleCloseErrorModal}>
              <CtaLink id={'import-error-modal-try-again'} type={'link'} action={handleCloseErrorModal}>Close</CtaLink>
            </div>
          </div>
        </SmallModal>
    </section>
  )
}
