import React, { useContext, useState, useEffect } from "react"
import { Link, useNavigate,useSearchParams, useLocation, NavLink } from "react-router-dom"
import Logo from "../../../entrypoints/images/logo240.webp"
import UserSquare from '../../../entrypoints/images/user-square.svg'
import { CurrentUserContext } from "../../../contexts/CurrentUser.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCartShopping} from '@fortawesome/free-solid-svg-icons'
import { OffersContext } from '../../../contexts/Offers.js'
import { getCartSkuCount } from '../../../utils/handleOffers.js'

import axios from 'axios'
import SecondaryCtaButton from "../../atoms/buttons/SecondaryCtaButton.tsx"
import NavHeader from '../headers/NavHeader.tsx'
const AccountHeader = ({ openSignInModal, signOut }) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const { currentUser } = useContext(CurrentUserContext)
  const { offers } = useContext(OffersContext)
  const [cartSkus, setCartSkus] = useState(0)
  const location = useLocation()
  let navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams()

  const getHomeLinkPath = () => {
    if (currentUser?.brand) {
      return '/dashboard'
    }
    return '/'
  }

  useEffect(() => {
    offers?.length && setCartSkus(getCartSkuCount(offers))
  }, [offers])

  return (
    <React.Fragment>
      <header className="fixed top-0 w-screen flex h-20 px-2 xl:px-8 bg-white z-50">
        <Link to={getHomeLinkPath()} className={"h-full w-1/6 xl:w-1/5 flex items-center"}>
          <img
            src={Logo}
            alt={"made me buy it logo"}
            id={"header-logo"}
            className="w-52"
          />
        </Link>

        <div
          className={
            "h-full w-2/3 xl:w-3/5 flex flex-col justify-center justify-center px-3 relative"
          }
        >
          <NavHeader />
        </div>

        <div className={'h-full w-1/6 xl:w-1/5 flex items-center justify-end'}>
          {
            currentUser?.email ? (
              <div className={'w-52 flex justify-between items-center'}>
                {
                  currentUser?.retailer &&
                  <NavLink to={'/cart'} className={'flex h-full w-12 relative'}>
                    <FontAwesomeIcon icon={faCartShopping} className={'h-10 w-10 text-primary'} alt={'shopping cart'} />
                    {
                      !!cartSkus &&
                      <div className={'h-5 w-5 bg-red-600 flex justify-center items-center rounded-full absolute -top-2 -right-1'}>
                        <p className={'text-light font-med text-xxs'}>{ cartSkus }</p>
                      </div>
                    }
                  </NavLink>
                }
                <div className={'shrink-0 w-24 lg:w-32'}>
                  <SecondaryCtaButton id={"header-sign-out"} onclick={signOut} >
                    <img src={UserSquare} alt={"user-icon"} className={"h-6 w-6 mr-2 hidden lg:inline"} />
                    <p className="w-fit">Sign Out</p>
                  </SecondaryCtaButton>
                </div>
              </div>
            ) : (
              <div className={'w-24 lg:w-32'}>
                <SecondaryCtaButton id={'header-sign-in-button'} onclick={() => {openSignInModal()}}>
                  <img src={UserSquare} alt={"user-icon"} className={"h-6 w-6 mr-2 hidden lg:inline"} />
                  <p className="w-fit">Sign In</p>
                </SecondaryCtaButton>
              </div>
            )}
        </div>
      </header>
    </React.Fragment>
  )
}

export default AccountHeader
