import React from 'react'
import { Link } from 'react-router-dom'
import EmptyBox from "../../../../entrypoints/images/nothing-uploaded.svg";
import Paragraph1 from "../../../../components/atoms/text/Paragraph1.tsx";
import FormatInt from "../../../../utils/FormatInt.js"
import FormatDollars from '../../../../utils/FormatDollars.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTriangleExclamation, faSquarePen, faCircleCheck } from '@fortawesome/free-solid-svg-icons'

const ProductIndexItem = ({ product }: { product: object }) => {
  return (
    <article className={"w-full border-b border-gray p-3 flex"}>
      <div className={'w-8'}>
        {
          product.provisional
            ? <FontAwesomeIcon icon={faTriangleExclamation} className={`h-5 w-5 text-red-600`} title={'product not published'} />
            : <FontAwesomeIcon icon={faCircleCheck} className={`h-5 w-5 text-green-500`} title={'successfully published'} />
        }
      </div>
      <img
        src={product.photo_1_url ? product.photo_1_url : EmptyBox}
        alt={"product image"}
        className={"h-32 w-32"}
      />
      <div className={"w-full ml-3"}>
        <div className={"w-full flex justify-between items-start"}>
          <div className={"mb-8"}>
            { product?.name ? <Paragraph1 styles={'mb-4'}>{product.name}</Paragraph1> : <Paragraph1 styles={'mb-4 text-red-600'}>Missing Product Name</Paragraph1> }
            { product?.units ? <Paragraph1>{`Units: ${FormatInt(product.units)}`}</Paragraph1> : <Paragraph1 styles={'text-red-600'}>Missing Units</Paragraph1> }
            { product?.msrp_cents ? <Paragraph1>{`MSRP per unit: ${FormatDollars(product.msrp_cents)}`}</Paragraph1> : <Paragraph1 styles={'text-red-600'}>Missing MSRP</Paragraph1> }
            { product?.seller_price_cents ? <Paragraph1>{`Asking price per unit: ${FormatDollars(product.seller_price_cents)}`}</Paragraph1> : <Paragraph1 styles={'text-red-600'}>Missing MSRP</Paragraph1> }
          </div>
          <div className={''}>
              {
               product.provisional ?
                 <div className={''}>
                   <Link to={`/dashboard/manage_products/${product.id}`} className={'w-full flex items-center justify-end'}>
                     <Paragraph1 styles={'mx-2 text-red-600'}>Fix issues with product details</Paragraph1>
                     <FontAwesomeIcon icon={faSquarePen} className={`h-5 w-5 text-red-600`} />
                   </Link>
                 </div>
                 :
                 <React.Fragment>
                   <div className={'mb-4'}>
                     <Link to={`/all_products/${product.brand_id}/${product.id}`} className={'w-full flex items-center justify-end'}>
                       <Paragraph1 styles={'mr-2'}>View published product</Paragraph1>
                       <FontAwesomeIcon icon={faEye} className={`h-5 w-5 text-almost-black`} />
                     </Link>
                   </div>
                   <div className={'w-full'}>
                     <Link to={`/dashboard/manage_products/${product.id}`} className={'w-full flex items-center justify-end'}>
                       <Paragraph1 styles={'mr-2'}>Edit product details</Paragraph1>
                       <FontAwesomeIcon icon={faSquarePen} className={`h-5 w-5 text-almost-black`} />
                     </Link>
                   </div>
                 </React.Fragment>
              }
          </div>
        </div>
      </div>
    </article>
  )
}

export default ProductIndexItem
