import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Formik, Form } from "formik"
import * as Yup from 'yup'
import {makeOption, productCategories, brandCategories, productGenders, packagingTypes} from '../../../utils/FormSelectOptions.jsx'
import ImageUpload from '../../../components/onboarding/molecules/ImageUpload.tsx'
import TextAreaInput from "../../../components/onboarding/molecules/TextAreaInput"
import TextInput from "../../molecules/inputs/TextInput"
import MoneyInput from "../../molecules/inputs/MoneyInput"
import DecimalInput from "../../molecules/inputs/DecimalInput"
import IntegerInput from "../../molecules/inputs/IntegerInput"
import ProfileSection from '../brand/molecules/ProfileSection.tsx'
import Header1 from "../../atoms/text/Header1"
import Header3 from "../../atoms/text/Header3"
import SelectInput from "~/components/onboarding/molecules/SelectInput"
import FormatMoney from "../../../utils/FormatMoney.js"
import FormatCapitalize from "../../../utils/FormatCapitalize.js"
import axios from 'axios'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons"
import WeightToggle from '../../molecules/inputs/WeightToggle.tsx'


const ProductEdit = () => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const [product, setProduct] = useState({})
  const params = useParams()
  let [successMsg, setSuccessMsg] = useState(null)
  let [isDisabled, setIsDisabled] = useState(false)
  let [deleteIsOpen, setDeleteOpen] = useState(false)
  let [weightUnit, setWeightUnit] = useState("lbs")
  let [weightLabel, setWeightLabel] = useState("lbs")
  let navigate = useNavigate()

  useEffect(() => {
    weightUnit === 'lbs' ? setWeightLabel('lbs') : setWeightLabel('oz')
  }, [weightUnit])

  const handleSubmit = (v) => {
    setIsDisabled(true)
    console.log(`L: ${typeof v.length}, H: ${typeof v.height} Width: ${typeof v.width}, Weight: ${typeof v.weight}`)
    axios.put(`/products/${product.id}`, {
      product: {
        upc: v.upc,
        name: v.name,
        photo_1: v.photo_1,
        photo_2: v.photo_2,
        photo_3: v.photo_3,
        photo_4: v.photo_4,
        photo_5: v.photo_5,
        msrp_cents: v.msrp_cents,
        wholesale_cents: v.wholesale_cents,
        seller_price_cents: v.seller_price_cents,
        units: v.units,
        min_order_units: v.min_order_units,
        sku: v.sku,
        asin: v.asin,
        style_number: v.style_number,
        description: v.description,
        category: v.category.toLowerCase(),
        gender: v.gender.toLowerCase(),
        seller_size: v.seller_size,
        expiration_date: v.expiration_date,
        packaging_type: v.packaging_type.toLowerCase(),
        length: v.length.toString(),
        width: v.width.toString(),
        height: v.height.toString(),
        weight: v.weight.toString(),
        weight_unit: v.weight_unit,
        inner_count: v.inner_count,
        inner_quantity: v.inner_quantity,
        outer_quantity: v.outer_quantity,
      }
    }
  ).then(() => {
        handleSuccess()
      }
    )
  }

  const handleSuccess = () => {
    setSuccessMsg('Success!')
    setTimeout(() => {
      navigate('/dashboard/manage_products')
    }, 1000)
  }

  const handleDelete = () => {
    setDeleteOpen(true)
  }

  const deleteProduct = () => {
    axios.delete(`/products/${product.id}`).then(() => {
      console.log(">>>", product.id)
      navigate('/dashboard/manage_products')
    })
  }

  let testCdn = (v) => {
    if (!v) {
      return true
    }
    return !v.includes('drive.google.com') && !v.includes('imgur.com') && !v.includes('ibb.co')
  }

  useEffect(() => {
    axios.get(`/products/${params.product_id}`).then((r) => {
      setProduct(r.data)
    })
  }, [])

  return (
    <section className={"w-2/3 flex flex-col"}>
      <Header1 styles={'mb-4'}>Edit Product</Header1>
      <Formik
        initialValues={{
          upc: product.upc || '',
          name: product.name || '',
          photo_1: product.photo_1 || '',
          photo_2: product.photo_2 || '',
          photo_3: product.photo_3 || '',
          photo_4: product.photo_4 || '',
          photo_5: product.photo_5 || '',
          msrp_cents: FormatMoney(product.msrp_cents) || 0,
          wholesale_cents: FormatMoney(product.wholesale_cents) || 0,
          seller_price_cents: FormatMoney(product.seller_price_cents) || 0,
          units: product.units || 0,
          min_order_units: product.min_order_units || 0,
          sku: product.sku || '',
          asin: product.asin || '',
          style_number: product.style_number || '',
          description: product.description || '',
          category: FormatCapitalize(product.category),
          gender: FormatCapitalize(product.gender),
          seller_size: product.seller_size || '',
          expiration_date: product.expiration_date || '',
          packaging_type: FormatCapitalize(product.packaging_type),
          length: product.length || '',
          height: product.width || '',
          width: product.height || '',
          weight: product.weight || '',
          weight_unit: product.weight_unit || 'lbs',
          inner_count: product.inner_count || '',
          inner_quantity: product.inner_quantity || '',
          outer_quantity: product.outer_quantity || '',
        }}
        initialTouched={{
          upc: true,
          name: true,
          photo_1: true,
          photo_2: true,
          photo_3: true,
          photo_4: true,
          photo_5: true,
          msrp_cents: true,
          wholesale_cents: true,
          seller_price_cents: true,
          units: true,
          min_order_units: true,
          sku: true,
          asin: true,
          style_number: true,
          description: true,
          category: true,
          gender: true,
          seller_size: true,
          expiration_date: true,
          packaging_type: true,
          length: true,
          width: true,
          height: true,
          weight: true,
          weight_unit: true,
          inner_count: true,
          inner_quantity: true,
          outer_quantity: true,
        }}
        enableReinitialize={true}
        validateOnMount={true}
        validationSchema={Yup.object({
          upc: Yup.string()
            .required('Required'),
          name: Yup.string()
            .required('Required'),
          photo_1: Yup.string()
            .test('valid cdn', 'Photo URL cannot link to drive.google.com, imgur.com, or ibb.co', (value) => testCdn(value))
            .required('At least one image required'),
          photo_2: Yup.string()
            .test('valid cdn', 'Photo URL cannot link to drive.google.com, imgur.com, or ibb.co', (value) => testCdn(value))
            .notRequired(),
          photo_3: Yup.string()
            .test('valid cdn', 'Photo URL cannot link to drive.google.com, imgur.com, or ibb.co', (value) => testCdn(value))
            .notRequired(),
          photo_4: Yup.string()
            .test('valid cdn', 'Photo URL cannot link to drive.google.com, imgur.com, or ibb.co', (value) => testCdn(value))
            .notRequired(),
          photo_5: Yup.string()
            .test('valid cdn', 'Photo URL cannot link to drive.google.com, imgur.com, or ibb.co', (value) => testCdn(value))
            .notRequired(),
          msrp_cents: Yup.number()
            .required('Required')
            .positive('Price must be greater than $0'),
          wholesale_cents: Yup.number()
            .required('Required')
            .positive('Price must be greater than $0'),
          seller_price_cents: Yup.number()
            .required('Required')
            .positive('Price must be greater than $0'),
          units: Yup.number()
            .required('Units must be greater than 0'),
          min_order_units: Yup.number()
            .notRequired()
            .positive("Must be greater than 0"),
          sku: Yup.string()
            .notRequired(),
          asin: Yup.string()
            .notRequired(),
          style_number: Yup.string()
            .notRequired(),
          description: Yup.string()
            .notRequired(),
          category: Yup.string()
            .notRequired(),
          gender: Yup.string()
            .notRequired(),
          seller_size: Yup.string()
            .notRequired(),
          expiration_date: Yup.string()
            .notRequired(),
          packaging_type: Yup.string()
            .notRequired(),
          length: Yup.number()
            .notRequired()
            .positive('Must be a positive number'),
          width: Yup.number()
            .notRequired()
            .positive('Must be a positive number'),
          height: Yup.number()
            .notRequired()
            .positive('Must be a positive number'),
          weight: Yup.number()
            .notRequired()
            .positive('Must be a positive number'),
          weight_unit: Yup.string()
            .notRequired(),
          inner_count: Yup.number().integer()
            .notRequired()
            .positive('Must be a positive number'),
          inner_quantity: Yup.number().integer()
            .notRequired()
            .positive('Must be a positive number'),
          outer_quantity: Yup.number().integer()
            .notRequired()
            .positive('Must be a positive number'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleSubmit(values)
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ isValid, errors }) => (
          <Form
          >
            <ProfileSection headerText={'Product Details'} >

              <TextInput
                label="Product UPC*"
                name="upc"
                type="text"
                placeholder=""
              />

              <TextInput
                label="Product Name*"
                name="name"
                type="text"
                placeholder=""
              />

              <MoneyInput
                label="Unit MSRP*"
                name="msrp_cents"
                placeholder=""
              />

              <MoneyInput
                label="Unit Wholesale Price*"
                name="wholesale_cents"
                placeholder=""
              />

              <MoneyInput
                label="Unit Asking Price*"
                name="seller_price_cents"
                placeholder=""
              />

              <IntegerInput
                label="Units*"
                name="units"
                type="number"
                placeholder=""
              />

              <IntegerInput
                label="Minimum Order Quantity"
                name="min_order_units"
                type="number"
                placeholder=""
              />

              <TextInput
                label="Product SKU"
                name="sku"
                type="text"
                placeholder=""
              />

              <TextInput
                label="Product ASIN"
                name="asin"
                type="text"
                placeholder=""
              />

              <TextInput
                label="Product Style Number"
                name="style_number"
                type="text"
                placeholder=""
              />

              <TextAreaInput
                label="Description"
                name="description"
                placeholder="About this product..."
                height='h-44'
              />

              <SelectInput label="Product Category" name="category" selectOptions={productCategories} />

              <SelectInput label="Gender" name="gender" selectOptions={productGenders} />

              <TextInput
                label="Seller Size"
                name="seller_size"
                type="text"
                placeholder=""
              />

              <TextInput
                label="Expiration Date"
                name="expiration_date"
                type="text"
                placeholder="MM/YYYY"
              />

              <SelectInput label="Packaging Type" name="packaging_type" selectOptions={packagingTypes} />

              <DecimalInput
                label="Length (inches)"
                name="length"
                unit=""
                precision={1}
                placeholder=""
                height='h-44'
              />

              <DecimalInput
                label="Width (inches)"
                name="width"
                unit=""
                precision={1}
                placeholder=""
                height='h-44'
              />

              <DecimalInput
                label="Height (inches)"
                name="height"
                unit=""
                precision={1}
                placeholder=""
                height='h-44'
              />

              <div className='col-span-12 grid grid-cols-2 gap-4'>
                <div>
                  <DecimalInput
                    label="Weight"
                    name="weight"
                    unit=""
                    precision={1}
                    placeholder=""
                    height='h-44'
                  />
                </div>

                <div>
                  <WeightToggle
                    selectOptions={["lbs", "oz"]}
                    name="weight_unit"
                    height='h-44'
                    label="Weight Unit"
                  />
                </div>
              </div>

              <IntegerInput
                label="Number of Inners"
                name="inner_count"
                type="number"
                placeholder=""
              />

              <IntegerInput
                label="Inner Quantity"
                name="inner_quantity"
                type="number"
                placeholder=""
              />

              <IntegerInput
                label="Outer Quantity"
                name="outer_quantity"
                type="number"
                placeholder=""
              />

              <div className={`w-full text-sm font-reg mt-4 ${errors.photo_1 && 'text-red-600 flex items-center'}`}>
                <p>Product Images</p>
                {
                  errors.photo_1 &&
                  <React.Fragment>
                    <FontAwesomeIcon icon={faTriangleExclamation} className={`h-3 w-3 text-red-600 mx-2`} />
                    <p>{errors.photo_1}</p>
                  </React.Fragment>
                }
              </div>
              <div className={'flex justify-between'}>
                <ImageUpload
                  name='photo_1'
                  recommendedSize={'400x400 pixels'}
                  initialPreview={product.photo_1}
                  width={'w-1/5'}
                />
                <ImageUpload
                  name='photo_2'
                  recommendedSize={'400x400 pixels'}
                  initialPreview={product.photo_2}
                  width={'w-1/5'}
                />
                <ImageUpload
                  name='photo_3'
                  recommendedSize={'400x400 pixels'}
                  initialPreview={product.photo_3}
                  width={'w-1/5'}
                />
                <ImageUpload
                  name='photo_4'
                  recommendedSize={'400x400 pixels'}
                  initialPreview={product.photo_4}
                  width={'w-1/5'}
                />
                <ImageUpload
                  name='photo_5'
                  recommendedSize={'400x400 pixels'}
                  initialPreview={product.photo_5}
                  width={'w-1/5'}
                />
              </div>

              <button
                type="submit"
                id={'product-edit-submit'}
                disabled={!isValid || isDisabled}
                className={`w-full h-12 bg-dark-gray text-xs font-reg text-white flex justify-center items-center mt-6 disabled:bg-gray disabled:cursor-not-allowed`}
              >
                <p className='w-fit'>Save Changes</p>
              </button>
              { <p className={`h-12 w-full text-center pt-4 font-reg text-base text-red-600`}>{ isValid ? "" : "Please check required fields"}</p> }
              <div className={'w-full flex justify-end relative'}>
                {
                  !deleteIsOpen ?
                    <button
                      type="button"
                      onClick={handleDelete}
                      id={'product-edit-delete'}
                      disabled={isDisabled}
                      className={`w-1/4 h-12 justify-self-end bg-red-600 text-xs font-reg text-white flex justify-center items-center mt-6 disabled:bg-gray disabled:cursor-not-allowed`}
                    >
                      <p className='w-fit'>Delete Product</p>
                    </button>
                    :
                    <div className={'w-1/4 flex flex-col pt-4 items-center'}>
                      <Header3 styles={'text-center'}>Are you sure you want to delete?</Header3>
                      <button
                        type="button"
                        onClick={deleteProduct}
                        id={'product-edit-delete'}
                        disabled={isDisabled}
                        className={`w-full h-12 justify-self-end bg-red-600 text-xs font-reg text-white flex justify-center items-center mt-6 disabled:bg-gray disabled:cursor-not-allowed`}
                      >
                        <p className='w-fit'>Yes, delete product</p>
                      </button>
                      <button
                        type="button"
                        onClick={() => setDeleteOpen(false)}
                        id={'product-edit-delete'}
                        disabled={isDisabled}
                        className={`w-full h-12 bg-white text-xs font-reg text-almost-black border border-almost-black flex justify-center items-center mt-6`}
                      >
                        <p className='w-fit'>No, keep editing</p>
                      </button>
                    </div>
                }
              </div>
              { successMsg && <p className={`h-12 w-full text-center pt-4 font-reg text-base text-pink`}>{successMsg}</p> }
            </ProfileSection>
          </Form>
        )}

      </Formik>
    </section>
  )
}

export default ProductEdit
