import React, { useState, useEffect } from 'react'

const ProductDetailsImages = ({ images }) => {

  let [selectedImage, setSelectedImage] = useState(null)
  let [imgIdx, setImgIdx] = useState(0)

  const changeImage = (i) => {
    setImgIdx(i)
    setSelectedImage(images[i])
  }

  useEffect(() => {
    images?.length && setSelectedImage(images[0])
  }, [images])

  return (
    <section className={'w-full flex flex-col lg:flex-row shrink-0 bg-white p-3 rounded-2xl mb-4'}>
      <aside className={'flex lg:flex-col'}>
        { images?.map((u, i) => {
          if (u.length) {
            return (
              <div className={`mb-2 p-0.5 rounded-md border hover:border-primary-light flex justify-center items-center ${i === imgIdx ? 'border-2 border-primary' : 'border-secondary'}`} onMouseEnter={() => changeImage(i)} >
                <img src={u} className={'w-20'} />
              </div>
            )}
        })}
      </aside>
      <img src={selectedImage} alt={'product image'} className={`m-auto w-80 lg:w-[400px]`}/>
    </section>
  )
}

export default ProductDetailsImages
