import React, { useState } from 'react'
import Paragraph1 from "../../components/atoms/text/Paragraph1"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

const CsvTroubleshooting = ({ isOpen, setOpen }) => {

  let tips = [
    'Unique UPCs are required for the file to import correctly. If you see fewer products imported than you expect, a repeated UPC is the likely cause.',
    'Check for blank rows in your csv. Having lots of blank rows can slow upload or cause import to fail.',
    'Make sure your column headings match the template exactly. Mismatches can lead to missing data.',
    'Photo URLs must be hosted with direct access to their raw content. Links to images hosted on services like Google Drive, Imgur, and Ibb will not work.',
    'Maximum .csv file size is 4MB.',
    'If your import was partially successful (i.e., some products or fields are missing, and you see incomplete data in My Products), you can edit the file and import it again. Previously imported fields will be skipped, but missing fields and new products (i.e., products with unique UPCs) will be added.'
  ]

  return (
    <section className={'mt-4 w-11/12 mx-auto p-3 bg-white border border-gray rounded-2xl'} id={'csv-tips-section'}>
      <button onClick={() => setOpen(!isOpen)} className={'w-full flex justify-between items-center'}>
        <Paragraph1 styles={'text-xl'}>Tips for successful file uploads and data imports</Paragraph1>
        <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} className={'h-5 w-5 text-primary'} />
      </button>
      {
        isOpen &&
        <div className={'mt-2 border-t border-secondary pt-2 '}>
          <ul className={'list-outside list-disc ml-4'}>

            {
              tips.map((tip) => (
                  <li>
                    <Paragraph1 styles={'text-base mb-2'}>{ tip }</Paragraph1>
                  </li>
                )
              )
            }
            <li>
              <Paragraph1 styles={'text-base mb-2'}>
                {/*Still having trouble? Email <a href={'mailto: support@mademebuyit.io'} className={'text-link-blue'}>support@mademebuyit.io</a>*/}
                Still having trouble? Chat with us on Whatsapp at <span className={'text-red-600 font-bold'}>+1 (XXX) XXX-XXXX</span>.
              </Paragraph1>
            </li>
          </ul>
        </div>
      }
    </section>
  )
}

export default CsvTroubleshooting
