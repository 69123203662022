import React, { useContext } from "react"
import { Link, useLocation } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { CurrentUserContext } from "../../contexts/CurrentUser.js"
import CtaButton from '../atoms/buttons/CtaButton.tsx'

export default function Footer({ openSignUpModal }) {
  const { currentUser } = useContext(CurrentUserContext)

  return (
    <footer className={'w-full h-28 flex bg-none border-t-2 border-gray bg-transparent-white'}>
      <div className="h-full w-1/3 grid place-content-center text-base font-reg text-almost-black">
        &copy; 2025 All Rights Reserved. mademebuyit, inc.
      </div>
      <div className={'h-full w-1/3 grid place-content-center'}>
        {
          !currentUser?.email &&
          <div className={'w-60'}>
            <CtaButton id={'footer-signin-button'} onclick={openSignUpModal}>Sign in / Sign up</CtaButton>
          </div>
        }
      </div>
      <div className={'h-full w-1/3 flex items-center justify-around'}>
        <div className={'flex'}>
          <Link to={'https://www.linkedin.com/company/mademebuyit/'} className={'p-2'} target={'_blank'}>
            <FontAwesomeIcon icon={faLinkedin} alt={'go to LinkedIn icon'} className={'h-6 w-6'}/>
          </Link>
        </div>
      </div>
    </footer>
  )
}
