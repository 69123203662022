import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import Paragraph1 from "~/components/atoms/text/Paragraph1";

const AddProductsDropdown = () => {
  let [isOpen, setOpen] = useState(false)

  let addProductsRef = useRef(null)

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (e.target.parentNode && !e.target.parentNode.matches('.add-products-dropdown')) {
        setOpen(false)
      }
    })
  }, [addProductsRef])

  return (
    <div className={`add-products-dropdown z-40 w-64 relative ${isOpen ? 'bg-none border-none mb-5' : 'border-b-4 border-primary rounded-full bg-primary mb-4 '}`}>
      <button
        className={`add-products-dropdown h-12 w-full border-2 border-primary rounded-full bg-primary font-light text-light text-xl transform translate-x-px transitional-all hover:transition-all hover:-translate-x-px hover:translate-y-px disabled:bg-gray disabled:text-almost-black disabled:border-none`}
        onClick={() => setOpen(!isOpen)}
        ref={addProductsRef}
      >
        <div className={'add-products-dropdown mx-4 flex justify-between items-center'}>
          <h2 className={"text-2xl font-light text-light"}>Add Products</h2>
          <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} className={`ml-4 h-5 w-5 text-light`} />
        </div>
      </button>
        {
          isOpen &&
          <div className={`absolute z-30 w-full bg-primary bg-opacity-50 rounded-3xl p-2`}>
            <div className={`h-12 w-full mb-2 border-2 border-primary rounded-full bg-light transform translate-x-px transitional-all hover:transition-all hover:-translate-x-px hover:translate-y-px hover:border-primary-light disabled:bg-gray disabled:text-almost-black disabled:border-none`} onClick={() => setOpen(!isOpen)} >
              <Link to="/dashboard/csv_import" className={'add-products-dropdown mx-3 my-3 flex justify-center'}>
                <FontAwesomeIcon icon={faUpload} className={`mr-2 h-4 w-4 text-primary-dark`} />
                <Paragraph1>Upload Products Via .CSV</Paragraph1>
              </Link>
            </div>
            <div className={`h-12 w-full border-2 border-primary rounded-full bg-light transform translate-x-px transitional-all hover:transition-all hover:-translate-x-px hover:translate-y-px hover:border-primary-light disabled:bg-gray disabled:text-almost-black disabled:border-none`} onClick={() => setOpen(!isOpen)} >
              <Link to="/dashboard/manage_products/new" className={'add-products-dropdown mx-3 my-3 flex justify-center'}>
                <Paragraph1>+ Add Manually</Paragraph1>
              </Link>
            </div>
          </div>
        }
    </div>
  )
}

export default AddProductsDropdown
