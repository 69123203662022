import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { CurrentUserContext } from "../../../contexts/CurrentUser"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCircleDot} from '@fortawesome/free-solid-svg-icons'
import { OffersContext } from '../../../contexts/Offers.js'
import { OrdersContext } from '../../../contexts/Orders.js'

const NavHeader = () => {

  const location = useLocation()
  const active = 'text-black border-b-2 border-primary '
  const inactive = 'text-secondary hover:border-b-2 hover:border-secondary '
  const styles = 'text-sm lg:text-xl font-reg text-center h-full w-fit w-32 grid place-content-center mx-4 relative'
  const { currentUser } = useContext(CurrentUserContext)
  let [isProducts, setProducts] = useState(null)
  let [isDashboard, setDashboard] = useState(null)
  let [newPicks, setNewPicks] = useState(null)
  let { offers } = useContext(OffersContext)
  let { orders } = useContext(OrdersContext)
  const [showOffersNotification, setShowOffersNotification] = useState(false)
  const [showOrdersNotification, setShowOrdersNotification] = useState(false)

  useEffect(() => {
    setProducts(location.pathname.includes('all_products'))
    setDashboard(location.pathname.includes('dashboard'))
    location.pathname.includes('my_picks') && setNewPicks(false)
  }, [location])

  useEffect(() => {
    setNewPicks(currentUser.new_picks)
  }, [currentUser])

  useEffect(() => {
    let states = []
    offers?.forEach((o) => {
      states.push(o.state)
    })
    let showDot = currentUser?.brand ? states.includes('seller_review') : states.includes('buyer_review')
    setShowOffersNotification(showDot)
  }, [offers])

  useEffect(() => {
    if (currentUser?.retailer) {
      let statuses = []
      orders?.forEach((o) => {
        statuses.push(o.status)
      })
      let showDot = statuses.includes('awaiting_initial_payment')
      setShowOrdersNotification(showDot)
    }
  }, [orders])

 return (
   <header className='w-full h-full flex justify-center items-center bg-white'>
     <nav className={'w-full h-10 flex justify-center'}>
       {
         currentUser?.brand && (
           <React.Fragment>
             <NavLink to={'/'} className={({ isActive }) => isActive || isDashboard ? active + styles : inactive + styles } id={'nav-dashboard-link'}>
               My Dashboard
             </NavLink>
             <NavLink to={`/all_brands/${currentUser.brand.id}`} className={({ isActive }) => isActive ? active + styles : inactive + styles } id={'nav-all-products-link'}>
               My Products
             </NavLink>
             <NavLink to={`/my_offers`} className={({ isActive }) => isActive ? active + styles : inactive + styles } id={'nav-offers-link'}>
               My Offers
               {
                 showOffersNotification && <FontAwesomeIcon icon={faCircleDot} className={'h-2.5 text-red-600 absolute top-1 -right-2.5'} />
               }
             </NavLink>
             <NavLink to={`/my_orders`} className={({ isActive }) => isActive ? active + styles : inactive + styles } id={'nav-orders-link'}>
               My Orders
             </NavLink>
           </React.Fragment>
         )
       }
       {
         currentUser?.retailer && (
           <React.Fragment>
             <NavLink to={'/'} className={({ isActive }) => (isActive || isProducts) ? active + styles : inactive + styles } id={'nav-all-products-link'}>
               All Products
             </NavLink>
             <NavLink to={'/my_offers'} className={({ isActive }) => isActive ? active + styles : inactive + styles } id={'nav-dashboard-link'}>
               My Offers
               {
                 showOffersNotification && <FontAwesomeIcon icon={faCircleDot} className={'h-2.5 text-red-600 absolute top-1 -right-2.5'} />
               }
             </NavLink>
             <NavLink to={'/my_orders'} className={({ isActive }) => isActive ? active + styles : inactive + styles } id={'nav-dashboard-link'}>
               My Orders
               {
                 showOrdersNotification && <FontAwesomeIcon icon={faCircleDot} className={'h-2.5 text-red-600 absolute top-1 -right-2.5'} />
               }
             </NavLink>
           </React.Fragment>
         )
       }
     </nav>
   </header>
 )
}

export default NavHeader
