import React from 'react'

const CtaButton = ({ id, submit, onclick, disabled, styles, children }: { id:string, submit?:boolean, onclick?:Function, disabled?:boolean, children?:any }) => {

  return (
    <div className={`h-12 w-full border-b-4 border-primary rounded-full bg-light hover:bg-inherit`}>
      <button
        id={id}
        type={submit ? 'submit' : ''}
        onClick={onclick}
        className={`
            h-12 w-full border-2 border-primary rounded-full bg-primary font-light text-light text-2xl grid place-content-center
            disabled:bg-gray disabled:text-almost-black disabled:border-none disabled:transition-none
            ${disabled ? 'disabled:transition-none' : `hover:transition-all hover:-translate-x-px hover:translate-y-px hover:bg-primary-light hover:border-primary-light hover:cursor-pointer ${styles}`}
        `}
        disabled={disabled || false}
      >
        { children }
      </button>
    </div>
  )
}

export default CtaButton
