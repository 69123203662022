import React, { useState, useEffect, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import axios from 'axios'
import Header2 from "../../../components/atoms/text/Header2.tsx"
import Order from "../../molecules/Order.jsx"
import Header1 from '../../../components/atoms/text/Header1'
import MessageSlider from "~/components/molecules/MessageSlider";
import { OrdersContext } from "../../../contexts/Orders.js"


export default function OrdersIndex() {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const [params, setParams] = useSearchParams()
  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState("")
  const [messageIsSuccess, setMessageIsSuccess] = useState(true)
  const { orders, setOrders } = useContext(OrdersContext)

  const parseOrders = (unparsedOrders) => {
    let parsedOrders = []
    let purchase_order_products = unparsedOrders.included

    unparsedOrders.data.forEach((o) => {
      let obj = o.attributes
      obj.purchase_order_products = o.relationships.purchase_order_products.data.map((elem) => {
        return purchase_order_products.find(op => op.id === elem.id)
      })
      parsedOrders.push(obj)
    })

    return parsedOrders.sort((a, b) => {
      return a.created_at - b.created_at
    })
  }

  useEffect(() => {
    if (params.get('stripe_success') === 'true') {
      setShowMessage(true)
      setMessage("Your payment is being processed.")
      setMessageIsSuccess(true)

      axios.put(`/marketplace/purchase_orders/${params.get('purchase_order_id')}`, { withCredentials: true }).then(() => {
        axios.get('/marketplace/purchase_orders').then(res => {
          setOrders(parseOrders(res.data))
        })
      })

    } else if (params.get('stripe_success') === 'false') {
      setShowMessage(true)
      setMessage("There was an issue with your payment.")
      setMessageIsSuccess(false)
    }
  }, [params])

  return(
    <section className={'w-[1020px] bg-light my-6'}>
      <Header1>Orders</Header1>
      {
        !orders?.length && <Header2 styles={'text-center my-12'}>You have no orders.</Header2>
      }
      {
        orders?.map((o) => {
          return o.state !== 'draft' && o.state !== 'saved_for_later' && <Order order={o} />
        })
      }
      <MessageSlider showMessage={showMessage} setShowMessage={setShowMessage} message={message} setMessage={setMessage} success={messageIsSuccess} />
    </section>
  )
}
