import React, { useEffect, useState } from "react"
import X from "~/entrypoints/images/x.svg"
import Overline from "../../atoms/text/Overline.tsx"
import Header2 from "../../atoms/text/Header2.tsx"
import CtaButton from "~/components/atoms/buttons/CtaButton.tsx"
import Paragraph1 from "~/components/atoms/text/Paragraph1"

const FiltersModal = ({
  isOpen,
  filters,
  setIsOpen,
  setMsrp,
  sortValue,
  setSort,
  numProducts,
  reset,
  setPercentOffMsrp,
  setUnitsAvailable,
  setPackagingType,
  setSellerPriceCents
}: {
  isOpen: boolean
  filters: object
  setIsOpen: Function
  setMsrp: Function
  sortValue: string
  setSort: Function
  numProducts: string
  reset: Function
  setPercentOffMsrp: Function
  setUnitsAvailable: Function
  setPackagingType: Function,
  setSellerPriceCents: Function
}) => {
  let [animate, setAnimate] = useState(false)
  let [isHidden, setIsHidden] = useState(true)

  const handleSellerPriceCentsChange = (e) => {
    setSellerPriceCents(0, Number(e.target.value))
  }

  const handleMsrpChange = (e) => {
    setMsrp(0, Number(e.target.value))
  }
  const handlePercentOffMsrpChange = (e) => {
    setPercentOffMsrp(Number(e.target.value))
  }

  const handleUnitsAvailableChange = (e) => {
    setUnitsAvailable(Number(e.target.value), 6)
  }

  const handlePackagingTypeChange = (e) => {
    if (e.target.value === "any") {
      setPackagingType("")
      return
    }
    console.log("packaging type: ", e.target.value)
    setPackagingType(e.target.value)
  }

  const handleSortChange = (e) => {
    setSort(e.target.value)
  }

  useEffect(() => {
    if (isOpen) {
      setIsHidden(false)
      setTimeout(() => {
        setAnimate(true)
      }, 10)
    } else {
      setAnimate(false)
      setTimeout(() => {
        setIsHidden(true)
      }, 510)
    }
  }, [isOpen])

  let radioStyles =
    "!appearance-none mr-2 border border-dark-gray transition-all checked:border-6 checked:border-primary checked:bg-primary focus:border-6 focus:border-primary focus:bg-primary"

  return (
    <div
      id={"all-filters-modal"}
      className={`fixed left-0 top-0 z-50 h-full w-full ${
        isHidden && "hidden"
      }`}
    >
      <div
        className={`grid h-full w-full place-content-center transition-all duration-500 ${
          animate ? "bg-transparent-gray" : "bg-none"
        }`}
      >
        <div
          className={`rounded-3xl bg-primary transition-all duration-500 ${
            animate
              ? "translate-x-0 translate-y-0 scale-100"
              : "-translate-x-[50vw] -translate-y-[50vh] scale-0"
          } `}
        >
          <div
            className={
              "relative h-[720px] w-[480px] -translate-y-2 translate-x-1.5 transform rounded-3xl bg-white p-3 backdrop-blur-xl"
            }
          >
            <button
              autoFocus={true}
              className={"absolute right-0 top-0 p-2 font-med text-xl"}
              onClick={() => setIsOpen(false)}
            >
              <img src={X} alt={"close modal"} className={"h-10 w-10"} />
            </button>
            <header
              className={
                "h-1/12 mt-4 flex w-full items-center justify-center border-b border-secondary"
              }
            >
              <Overline styles={"mb-4"}>All Filters</Overline>
            </header>

            <section className={"grid h-5/6 w-full grid-cols-2 gap-x-4 pl-2"}>
              <article className={`border-b border-secondary py-3`}>
                <Header2 styles={"mb-2"}>MSRP</Header2>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"msrp"}
                    id={"<$5"}
                    value={"1"}
                    checked={filters.msrp.max === 1}
                    onChange={handleMsrpChange}
                    className={radioStyles}
                  />
                  <label htmlFor={"<$5"}>
                    <Paragraph1>{"<$5"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"msrp"}
                    id={"<$10"}
                    value={"2"}
                    checked={filters.msrp.max === 2}
                    onChange={handleMsrpChange}
                    className={radioStyles}
                  />
                  <label htmlFor={"<$10"}>
                    <Paragraph1>{"<$10"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"msrp"}
                    id={"<$15"}
                    value={"3"}
                    checked={filters.msrp.max === 3}
                    onChange={handleMsrpChange}
                    className={radioStyles}
                  />
                  <label htmlFor={"<$15"}>
                    <Paragraph1>{"<$15"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"msrp"}
                    id={"<$20"}
                    value={"4"}
                    checked={filters.msrp.max === 4}
                    onChange={handleMsrpChange}
                    className={radioStyles}
                  />
                  <label htmlFor={"<$20"}>
                    <Paragraph1>{"<$20"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"msrp"}
                    id={"<$30"}
                    value={"5"}
                    checked={filters.msrp.max === 5}
                    onChange={handleMsrpChange}
                    className={radioStyles}
                  />
                  <label htmlFor={"<$30"}>
                    <Paragraph1>{"<$30"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"msrp"}
                    id={"any"}
                    value={"6"}
                    checked={filters.msrp.max === 6}
                    onChange={handleMsrpChange}
                    className={radioStyles}
                    defaultChecked={true}
                  />
                  <label htmlFor={"any"}>
                    <Paragraph1>{"Any"}</Paragraph1>
                  </label>
                </div>
              </article>
              <article className={`border-b border-secondary py-3`}>
                <Header2 styles={"mb-2"}>Seller Price</Header2>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"sellerPriceCents"}
                    id={"<$5"}
                    value={"1"}
                    checked={filters.sellerPriceCents.max === 1}
                    onChange={handleSellerPriceCentsChange}
                    className={radioStyles}
                  />
                  <label htmlFor={"<$5"}>
                    <Paragraph1>{"<$5"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"sellerPriceCents"}
                    id={"<$10"}
                    value={"2"}
                    checked={filters.sellerPriceCents.max === 2}
                    onChange={handleSellerPriceCentsChange}
                    className={radioStyles}
                  />
                  <label htmlFor={"<$10"}>
                    <Paragraph1>{"<$10"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"sellerPriceCents"}
                    id={"<$15"}
                    value={"3"}
                    checked={filters.sellerPriceCents.max === 3}
                    onChange={handleSellerPriceCentsChange}
                    className={radioStyles}
                  />
                  <label htmlFor={"<$15"}>
                    <Paragraph1>{"<$15"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"sellerPriceCents"}
                    id={"<$20"}
                    value={"4"}
                    checked={filters.sellerPriceCents.max === 4}
                    onChange={handleSellerPriceCentsChange}
                    className={radioStyles}
                  />
                  <label htmlFor={"<$20"}>
                    <Paragraph1>{"<$20"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"sellerPriceCents"}
                    id={"<$30"}
                    value={"5"}
                    checked={filters.sellerPriceCents.max === 5}
                    onChange={handleSellerPriceCentsChange}
                    className={radioStyles}
                  />
                  <label htmlFor={"<$30"}>
                    <Paragraph1>{"<$30"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"sellerPriceCents"}
                    id={"any"}
                    value={"6"}
                    checked={filters.sellerPriceCents.max === 6}
                    onChange={handleSellerPriceCentsChange}
                    className={radioStyles}
                    defaultChecked={true}
                  />
                  <label htmlFor={"any"}>
                    <Paragraph1>{"Any"}</Paragraph1>
                  </label>
                </div>
              </article>
              <article className={`border-b border-secondary py-3`}>
                <Header2 styles={"mb-2"}>% Off MSRP</Header2>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"percentOffMsrp"}
                    id={">90%"}
                    value={"1"}
                    checked={filters.percentOffMsrp.min === 1}
                    onChange={handlePercentOffMsrpChange}
                    className={radioStyles}
                  />
                  <label htmlFor={">90%"}>
                    <Paragraph1>{">90%"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"percentOffMsrp"}
                    id={">80%"}
                    value={"2"}
                    checked={filters.percentOffMsrp.min === 2}
                    onChange={handlePercentOffMsrpChange}
                    className={radioStyles}
                  />
                  <label htmlFor={">80%"}>
                    <Paragraph1>{">80%"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"percentOffMsrp"}
                    id={">70%"}
                    value={"3"}
                    checked={filters.percentOffMsrp.min === 3}
                    onChange={handlePercentOffMsrpChange}
                    className={radioStyles}
                  />
                  <label htmlFor={">70%"}>
                    <Paragraph1>{">70%"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"percentOffMsrp"}
                    id={">60%"}
                    value={"4"}
                    checked={filters.percentOffMsrp.min === 4}
                    onChange={handlePercentOffMsrpChange}
                    className={radioStyles}
                  />
                  <label htmlFor={">60%"}>
                    <Paragraph1>{">60%"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"percentOffMsrp"}
                    id={">50%"}
                    value={"5"}
                    checked={filters.percentOffMsrp.min === 5}
                    onChange={handlePercentOffMsrpChange}
                    className={radioStyles}
                  />
                  <label htmlFor={">50%"}>
                    <Paragraph1>{">50%"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"percentOffMsrp"}
                    id={"any"}
                    value={"0"}
                    checked={filters.percentOffMsrp.min === 0}
                    onChange={handlePercentOffMsrpChange}
                    className={radioStyles}
                    defaultChecked={true}
                  />
                  <label htmlFor={"any"}>
                    <Paragraph1>{"Any"}</Paragraph1>
                  </label>
                </div>
              </article>
              <article className={`border-b border-secondary py-3`}>
                {/* this is the section for available units */}
                <Header2 styles={"mb-2"}>Available Units</Header2>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"unitsAvailable"}
                    id={">100"}
                    value={"1"}
                    checked={filters.unitsAvailable.min === 1}
                    onChange={handleUnitsAvailableChange}
                    className={radioStyles}
                  />
                  <label htmlFor={">100"}>
                    <Paragraph1>{">100"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"unitsAvailable"}
                    id={">500"}
                    value={"2"}
                    checked={filters.unitsAvailable.min === 2}
                    onChange={handleUnitsAvailableChange}
                    className={radioStyles}
                  />
                  <label htmlFor={">500"}>
                    <Paragraph1>{">500"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"unitsAvailable"}
                    id={">1000"}
                    value={"3"}
                    checked={filters.unitsAvailable.min === 3}
                    onChange={handleUnitsAvailableChange}
                    className={radioStyles}
                  />
                  <label htmlFor={">1000"}>
                    <Paragraph1>{">1000"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"unitsAvailable"}
                    id={">5000"}
                    value={"4"}
                    checked={filters.unitsAvailable.min === 4}
                    onChange={handleUnitsAvailableChange}
                    className={radioStyles}
                  />
                  <label htmlFor={">5000"}>
                    <Paragraph1>{">5000"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"unitsAvailable"}
                    id={">10000"}
                    value={"5"}
                    checked={filters.unitsAvailable.min === 5}
                    onChange={handleUnitsAvailableChange}
                    className={radioStyles}
                  />
                  <label htmlFor={">10000"}>
                    <Paragraph1>{">10000"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"unitsAvailable"}
                    id={"any"}
                    value={"0"}
                    checked={filters.unitsAvailable.min === 0}
                    onChange={handleUnitsAvailableChange}
                    className={radioStyles}
                    defaultChecked={true}
                  />
                  <label htmlFor={"any"}>
                    <Paragraph1>{"Any"}</Paragraph1>
                  </label>
                </div>
              </article>
              <article className={`border-b border-secondary py-3`}>
                <Header2 styles={"mb-2"}>Packaging Type</Header2>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"shipping"}
                    id={"other-packaging-type"}
                    value={"other packaging type"}
                    className={radioStyles}
                    onChange={handlePackagingTypeChange}
                    checked={filters.packagingType === "other packaging type"}
                  />
                  <label htmlFor={"other-packaging-type"}>
                    <Paragraph1>{"Other Packaging Type"}</Paragraph1>
                  </label>
                </div>
                {/* the other packaging types are "Retail Ready" and "ecommerce ready" */}
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"shipping"}
                    id={"retail-ready"}
                    value={"retail ready"}
                    className={radioStyles}
                    onChange={handlePackagingTypeChange}
                    checked={filters.packagingType === "retail ready"}
                  />
                  <label htmlFor={"retail-ready"}>
                    <Paragraph1>{"Retail Ready"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"shipping"}
                    id={"ecommerce-ready"}
                    value={"ecommerce ready"}
                    className={radioStyles}
                    onChange={handlePackagingTypeChange}
                    checked={filters.packagingType === "ecommerce ready"}
                  />
                  <label htmlFor={"ecommerce-ready"}>
                    <Paragraph1>{"Ecommerce Ready"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"shipping"}
                    id={"any"}
                    value={"any"}
                    className={radioStyles}
                    onChange={handlePackagingTypeChange}
                    checked={!!!filters.packagingType}
                    defaultChecked={true}
                  />
                  <label htmlFor={"any"}>
                    <Paragraph1>{"Any"}</Paragraph1>
                  </label>
                </div>
              </article>
              <article className={`h-54 border-b border-secondary py-3`}>
                <Header2 styles={"mb-2"}>Sort By</Header2>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"sort"}
                    id={"newest"}
                    value={"newest"}
                    checked={sortValue === "newest"}
                    onChange={handleSortChange}
                    className={radioStyles}
                    defaultChecked={true}
                  />
                  <label htmlFor={"newest"}>
                    <Paragraph1>{"Newest"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"sort"}
                    id={"msrp_cents_asc"}
                    value={"msrp_cents_asc"}
                    checked={sortValue === "msrp_cents_asc"}
                    onChange={handleSortChange}
                    className={radioStyles}
                  />
                  <label htmlFor={"msrp_cents_asc"}>
                    <Paragraph1>{"Price: low to high"}</Paragraph1>
                  </label>
                </div>
                <div className={"flex items-center"}>
                  <input
                    type={"radio"}
                    name={"sort"}
                    id={"msrp_cents_desc"}
                    value={"msrp_cents_desc"}
                    checked={sortValue === "msrp_cents_desc"}
                    onChange={handleSortChange}
                    className={radioStyles}
                  />
                  <label htmlFor={"msrp_cents_desc"}>
                    <Paragraph1>{"Price: high to low"}</Paragraph1>
                  </label>
                </div>
              </article>
            </section>

            <footer
              className={
                "h-1/12 fixed bottom-0 left-0 flex w-full items-center justify-between p-3"
              }
            >
              <button
                className={"h-12 w-1/4 rounded-full border-2 border-primary"}
                onClick={reset}
              >
                <Paragraph1>Clear All</Paragraph1>
              </button>
              <div className={"w-1/2"}>
                <CtaButton
                  id={"filter-modal-apply-button"}
                  disabled={numProducts === "No matching products"}
                  onclick={() => setIsOpen(false)}
                >
                  {numProducts}
                </CtaButton>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FiltersModal
