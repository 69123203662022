import React from 'react'
import { Link } from 'react-router-dom'

const CtaLink = ({ id, link, styles, children }: { id:string, link:string, children:any }) => {
  return (
    <Link
      to={link || '/'}
      id={id}
      className={'w-full'}
    >
    <div className={`h-12 w-full border-2 border-primary rounded-full bg-primary font-light text-light text-2xl grid place-content-center
    hover:transition-all hover:-translate-x-px hover:translate-y-px hover:bg-primary-light hover:border-primary-light hover:cursor-pointer
    disabled:bg-gray disabled:text-almost-black disabled:border-none ${styles}`}>

        { children }
    </div>
    </Link>
  )
}

export default CtaLink
