import React, { useRef, useEffect } from "react"
import X from '../../entrypoints/images/x.svg'

const Modal = ({ isOpen, setIsOpen, width, children }) => {
  const modalRef = useRef(null)

  useEffect(() => {
    if (isOpen) {
      const modalElement = modalRef.current
      modalElement.focus()
      //add any focusable HTML element you want to include to this string
      const focusableElements = modalElement.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1]

      const handleTabKeyPress = (event) => {
        if (event.key === "Tab") {
          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          } else if (
            !event.shiftKey &&
            document.activeElement === lastElement
          ) {
            event.preventDefault();
            firstElement.focus();
          }
        }
      }

      const handleEscapeKeyPress = (event) => {
        if (event.key === "Escape") {
          setIsOpen(false);
        }
      }

      modalElement.addEventListener("keydown", handleTabKeyPress);
      document.addEventListener("keydown", handleEscapeKeyPress);

      return () => {
        modalElement.removeEventListener("keydown", handleTabKeyPress);
        document.removeEventListener("keydown", handleEscapeKeyPress)
      }
    }
  }, [isOpen, setIsOpen])

  return (
    isOpen && (
      <div className={"h-screen w-screen fixed top-0 left-0 grid place-content-center bg-transparent-gray z-50"} ref={modalRef} >
        {/*<div className={`rounded-3xl bg-primary`}>*/}
          <div className={ `relative max-h-[640px] ${width || 'w-[420px]'} rounded-3xl bg-white border-2 border-primary backdrop-blur-xl p-3 overflow-scroll shadow-xl` } >
            <button  autoFocus={true} className={'absolute top-0 right-0 font-med text-xl p-2'} onClick={() => setIsOpen(false)}>
              <img src={X} alt={'close modal'} className={'h-10 w-10'} />
            </button>
            { children }
          </div>
        {/*</div>*/}
      </div>
    )
  )
}

export default Modal

