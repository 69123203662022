import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import CartOfferProductRow from "~/components/CartOfferProductRow";
import {getOfferTotal, parseOffers} from "~/utils/handleOffers";
import CtaButton from "~/components/atoms/buttons/CtaButton";
import { CurrentEditContext } from "~/contexts/CurrentEdit";
import { OffersContext } from '../contexts/Offers.js'
import axios from 'axios'

const CartOffer = ({ offer, setShowMessage, setMessage, setMessageIsSuccess }) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const [currentEdit, setCurrentEdit] = useState("")
  let { offers, setOffers } = useContext(OffersContext)

  const handleSubmit = () => {
    axios.put(`/offers/${offer.id}`, {
      id: offer.id,
      state: 'seller_review'
    }).then((res) => {
      if (res.status === 200) {
        let m = <Link to={'/my_offers'}>Success! View offer in My Offers.</Link>
        setMessage(m)
        setMessageIsSuccess(true)
        setShowMessage(true)
      } else {
        setMessage("Oops, something went wrong.")
        setMessageIsSuccess(false)
        setShowMessage(true)
      }
      axios.get('/offers').then((res) => {
        setOffers(parseOffers(res.data))
      })
    }).catch(() => {
      setMessage("Oops, something went wrong.")
      setMessageIsSuccess(false)
      setShowMessage(true)
    })
  }

  const handleCheckout = () => {
    axios.put(`offers/${offer.id}`, {
      state: 'accepted'
    }).then(() => {
      axios.get('/offers').then((res) => {
        setOffers(parseOffers(res.data))
      })
    })
    console.log("Begin Stripe Checkout")
  }

  return (
    <CurrentEditContext.Provider value={{ currentEdit, setCurrentEdit }} >
      <article className={`my-6 border border-secondary rounded-xl p-4 ${!!currentEdit ? 'bg-light-gray' : 'bg-light'}`}>
        <h2 className={'text-xl font-light text-dark'}>{offer?.seller_name}</h2>
        {
          offer.offer_products.map((op) => {
            return (
              <CartOfferProductRow offerProduct={op} offerId={offer.id} state={offer.state} editing={op.id === currentEdit} />
            )
          })
        }
        <h3 className={'text-3xl font-med text-dark my-4 text-right'}>{`Total: ${getOfferTotal(offer)}`}</h3>
        {
          offer?.buyable ? <CtaButton id={'cart-buy-now'} disabled={!!currentEdit} onclick={handleCheckout}>Buy now</CtaButton>
            : <CtaButton id={'cart-submit-offer'} disabled={!!currentEdit} onclick={handleSubmit}>Submit offer</CtaButton>
        }
      </article>
    </CurrentEditContext.Provider>
  )
}
export default CartOffer
