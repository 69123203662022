import React, { useContext } from 'react'
import DashNavLink from "../molecules/DashNavLink.tsx"
import { CurrentUserContext } from '../../../../contexts/CurrentUser.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faEnvelope } from '@fortawesome/free-solid-svg-icons'

const DashboardNav = () => {
  const { currentUser } = useContext(CurrentUserContext)
  const buttonClass = (v) => {
    if (props.currentView === v) {
      return "col-span-12 grid grid-cols-2 p-5 border bg-black text-white"
    } else {
      return "col-span-12 grid grid-cols-2 p-5 border bg-white"
    }
  }

  return (
    <nav className={"w-11/12 max-w-xs"}>
      {/*<DashNavLink path={'dashboard/overview'} title={'My Offers'}></DashNavLink>*/}
      {/*<DashNavLink path={'dashboard/manage_orders'} title={'My Orders'}></DashNavLink>*/}
      <DashNavLink path={'dashboard/manage_products'} title={'My Products'}></DashNavLink>
      <DashNavLink path={'dashboard/brand_profile'} title={'My Profile'}></DashNavLink>
      <DashNavLink path={'dashboard/csv_import'} title={'CSV Import'}>
        <FontAwesomeIcon icon={faUpload} className={'mr-2 text-primary-light'} />
      </DashNavLink>
    </nav>
  )
}

export default DashboardNav
