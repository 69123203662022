import React, { useState, useContext, useEffect } from 'react'
import { OffersContext } from "../../contexts/Offers.js";
import CtaButton from "~/components/atoms/buttons/CtaLink";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import Header1 from '../atoms/text/Header1.tsx'
import axios from 'axios'
import {getDraftOffers, getOfferTotal, getSavedOffers} from '../../utils/handleOffers.js'
import CartOfferProductRow from "~/components/CartOfferProductRow";
import CartOffer from '../CartOffer.jsx'
import Header2 from "~/components/atoms/text/Header2";
import MessageSlider from "~/components/molecules/MessageSlider";


const Cart = () => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  let { offers, setOffers } = useContext(OffersContext)
  let [draftOffers, setDraftOffers] = useState([])
  let [savedOffers, setSavedOffers] = useState([])
  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState("")
  const [messageIsSuccess, setMessageIsSuccess] = useState(true)

  useEffect(() => {
    if (offers.length) {
      setDraftOffers(getDraftOffers(offers))
      setSavedOffers(getSavedOffers(offers))
    }
  }, [offers])

  return (
    <section className={'w-[1024px] my-6'}>
      <Header1>Cart</Header1>
      { !draftOffers.length && <Header2 styles={'text-center my-12'}>Your cart is empty.</Header2> }
      { draftOffers?.map((o) => {
        return <CartOffer offer={o} setShowMessage={setShowMessage} setMessage={setMessage} setMessageIsSuccess={setMessageIsSuccess} />
      }) }
      { !!savedOffers.length && <Header1>Saved For Later</Header1> }
      { savedOffers?.map((offer) => {
        return <CartOffer offer={offer} setShowMessage={setShowMessage} setMessage={setMessage} setMessageIsSuccess={setMessageIsSuccess} />
      }) }
      <MessageSlider showMessage={showMessage} setShowMessage={setShowMessage} message={message} setMessage={setMessage} success={messageIsSuccess} />
    </section>
  )
}

export default Cart


