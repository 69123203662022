import React from 'react'
import { Link } from 'react-router-dom'
import Paragraph1 from '../atoms/text/Paragraph1.tsx'

const BrandCard = ({brand}: {brand: object}) => {
  return (
      <Link to={`/all_brands/${brand.id}`} className={'h-fit w-48 shrink-0 flex flex-col items-center'}>
        <div className={'h-44 w-full bg-white flex justify-center items-center overflow-clip'}>
          <img src={brand.logo_url} alt={'brand image'} className={'w-full'}/>
        </div>
        <Paragraph1 styles={'mt-4'}>{brand.name}</Paragraph1>
      </Link>
  )
}

export default BrandCard
