import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import {
  faSquarePen,
  faTrashCan,
  faSquareCheck,
} from "@fortawesome/free-solid-svg-icons";
import FormatInt from "../../utils/FormatInt";
import FormatDollars from "../../utils/FormatDollars";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OffersContext } from "../../contexts/Offers.js";
import axios from 'axios'
import { parseOffers } from '../../utils/handleOffers.js'

const OfferProductRow = ({ offerProduct, offerId, state, counterOffer, setCounterOffer }) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const [product, setProduct] = useState()
  const { offers, setOffers } = useContext(OffersContext)

  useEffect(() => {
    setProduct(offerProduct.attributes.product)
  }, [offerProduct])

  return (
    <React.Fragment>
      <Link to={`/all_products/${offerProduct.attributes.product?.brand_id}/${offerProduct.attributes.product?.id}`} className={"w-60"}>
        <p className={"font-reg text-sm text-dark mb-2"}>{product?.upc}</p>
        <div className={"flex"}>
          <img src={offerProduct?.attributes.images[0]} className={"h-16 w-16"} />
          <p className={"font-reg text-lg text-dark ml-2"}>{product?.name}</p>
        </div>
      </Link>
      <div className={"w-7/12 grid grid-cols-11"}>
        <div className={`text-dark font-light flex flex-col justify-end items-center col-span-3 text-center`}>
          <p className={'text-xs'}>{ FormatInt(product?.units) + " available"}</p>
          <p className={`text-base my-2`}>{ FormatInt(offerProduct?.attributes.quantity) }</p>
          <p className={`text-xs`}>units</p>
        </div>
        <p className={"text-sm font-dark font-light grid justify-center items-end mb-6"}>X</p>
        <div className={`text-dark font-light flex flex-col justify-end items-center col-span-3 text-center`}>
          <p className={'text-xs'}>{ FormatDollars(product?.seller_price_cents) + " asking price"}</p>
          <p className={`text-base my-2`}>{ FormatDollars(offerProduct?.attributes.unit_price) }</p>
          <p className={`text-xs`}>per unit</p>
        </div>
        <p className={"text-sm font-dark font-light grid justify-center items-end mb-6"}>=</p>
        <div className={'text-dark font-light flex flex-col justify-end items-center col-span-3 text-center'}>
          <p className={'text-base my-2'}>{FormatDollars(offerProduct.attributes.total_price)}</p>
          <p className={'text-xs'}>total</p>
        </div>

        <div className={"flex flex-col justify-between items-end"}>
          {
            counterOffer &&
            <React.Fragment>
              {
                editing ?
                  <button className={"font-reg text-sm text-dark hover:underline disabled:text-gray disabled:hover:no-underline"} onClick={saveProduct} disabled={!!showPriceError || showQuantityError} >
                    Save
                    <FontAwesomeIcon icon={faSquareCheck} className={"h-4 w-4 ml-2"} />
                  </button>
                  :
                  <button className={"font-reg text-sm text-dark hover:underline disabled:text-gray disabled:hover:no-underline"} onClick={editProduct} disabled={!editable} >
                    Edit
                    <FontAwesomeIcon icon={faSquarePen} className={"h-4 w-4 ml-2"} />
                  </button>
              }
              <button className={"font-reg text-sm text-dark hover:underline disabled:text-gray disabled:hover:no-underline"} onClick={handleRemove} disabled={editing || !editable}>
                Remove
                <FontAwesomeIcon icon={faTrashCan} className={"h-4 w-4 ml-2"} />
              </button>
            </React.Fragment>
          }
        </div>
      </div>
    </React.Fragment>
  )
}

export default OfferProductRow
