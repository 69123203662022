import React, { useContext, useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import FormatCapitalize from "~/utils/FormatCapitalize";
import FormatInt from '../../utils/FormatInt.js'
import FormatDollars from '../../utils/FormatDollars.js'

const ProductCard = ({product, removePick}: {product: object, removePick?: Function}) => {
  let [imageLoading, setImageLoading] = useState(true)

  const ellipsedName = (n) => {
    if (n.length > 45) {
      return n.slice(0, 42) + '...'
    } else {
      return n
    }
  }

  return (
    <section className={`h-fit w-48 shrink-0 flex flex-col bg-transparent-white border border-gray relative -z-100`} id={`product-${product.id}-card`}>
      { imageLoading &&
        <div className={'h-96 w-full grid place-content-center absolute top-0 left-0'}>
          <FontAwesomeIcon icon={faSpinner} spinPulse className={`h-14 w-14 text-secondary`} />
        </div>
      }
      <div  className={`w-full flex flex-col ${imageLoading && 'invisible'}`}>
        { product && <Link to={`/all_products/${product.brand_id}/${product.id}`}>
          <div className={'h-44 w-full bg-white flex justify-center items-center overflow-clip '}>
            <img src={product.photo_1_url} alt={'brand image'} onLoad={() => setImageLoading(false)} loading={"lazy"} className={`w-full ${imageLoading ? 'image-loading invisible' : 'visible'}`}/>
          </div>
            <div className={'h-40 w-full p-3 flex flex-col justify-between border-b border-gray'}>
              <div>
                {
                  product?.category !== "other category" &&
                  <div className={'bg-primary rounded-sm w-fit px-1.5 mb-2'}>
                    <p className={'text-light font-reg text-xs tracking-wider'}>{FormatCapitalize(product.category)}</p>
                  </div>
                }
                <p className={`text-dark font-med text-sm ${product?.category == "other category" && "mt-6"}`}>{ellipsedName(product?.name)}</p>
              </div>

              <div className={'flex flex-col justify-end'}>
                <p className={`text-dark font-light text-sm`}>{FormatInt(product.units, true) + " units"}</p>
                <p className={`text-dark font-light text-sm`}>{FormatDollars(product.seller_price_cents, false) + " per unit"}</p>
                <p className={`text-red-600 font-light text-sm`}>{product.percent_off_msrp + "% off MSRP"}</p>
              </div>
            </div>
        </Link> }
      </div>
    </section>
  )
}
export default ProductCard
