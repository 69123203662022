import axios from 'axios'
import React, {useState, useEffect} from 'react'


export default function ManagePurchaseOrders() {
  axios.defaults.headers.common["X-CSRF-TOKEN"] = document.querySelector("[name=csrf-token]").content
  let [purchaseOrders, setPurchaseOrders] = useState([])

  useEffect(() => {
    getPurchaseOrders()
  }, [])

  const stateMatrix = {
    awaiting_initial_payment: {
      backward: [],
      forward: ["awaiting_shipment", "canceled"]
    },
    awaiting_shipment: {
      forward: ["shipped", "canceled"],
      backward: ["awaiting_initial_payment"]
    },
    shipped: {
      forward: ["received", "canceled"],
      backward: ["awaiting_shipment"]
    },
    received: {
      forward: ["paid_in_full"],
      backward: ["shipped"]
    },
    paid_in_full: {
      forward: ["completed"],
      backward: ["received"]
    },
    completed: {
      forward: [],
      backward: []
    },
    canceled: {
      forward: [],
      backward: []
    }
  }

  const StateMover = ({ purchaseOrder }) => {
    const moveStatus = (status) => {
      axios.put(`/admin/purchase_orders/${purchaseOrder.id}/move_status`, { status: status }, { withCredentials: true }).then(() => {
        getPurchaseOrders()
      })
    }

    return (
      <div className='grid grid-cols-12 text-left'>
        <div class='col-span-6 w-full'>
        {stateMatrix[purchaseOrder.status]?.backward?.map((status) => (
          <button
            key={status}
            onClick={() => moveStatus(status)}
            className="bg-red-500 text-white m-2 px-2 rounded rounded-full"
          >
            {status}
          </button>
        ))}
        </div>
        {/* here is the forward action(s) */}
        <div class='col-span-6 w-full'>
        {stateMatrix[purchaseOrder.status]?.forward?.map((status) => (
          <button
            key={status}
            onClick={() => moveStatus(status)}
            className="bg-green-500 text-white m-2 px-2 rounded rounded-full"
          >
            {status}
          </button>
        ))}
        </div>
      </div>
    )
  }

  const AdministratablePurchaseOrder = ({ purchaseOrder }) => {
    return (
      <div className="col-span-12 mt-2">
        <hr/>
        <div className="grid grid-cols-12 text-left font-reg text-sm">
          <div className='col-span-2 w-full'>{purchaseOrder.buyer_name}</div>
          <div className='col-span-2 w-full'>{purchaseOrder.seller_name}</div>
          <div className='col-span-2 w-full grid grid-cols-2'>
            <div className='w-full'>
              {purchaseOrder.sku_count}
            </div>
            <div className='w-full'>
              {purchaseOrder.total_cents / 100}
            </div>
          </div>
          <div className='col-span-2 w-full'>
              {purchaseOrder.status}
          </div>
          <div className='col-span-4 w-full'><StateMover purchaseOrder={purchaseOrder}></StateMover></div>
        </div>
      </div>
    )
  }

  const getPurchaseOrders = () => {
    axios.get('/admin/purchase_orders', { withCredentials: true }).then((response) => {
      setPurchaseOrders(response.data.map(p => p.attributes))
    })
  }
  return (
    <div className="my-2 grid grid-cols-12">
      <div className="col-span-12 grid grid-cols-12 text-left font-bold">
        <div className="col-span-2 w-full">Buyer</div>
        <div className="col-span-2 w-full">Seller</div>
        <div className="col-span-2 grid w-full grid-cols-2">
          <div className="w-full"># SKUs</div>
          <div className="w-full">Total</div>
        </div>
        <div className="col-span-2 w-full">Current Status</div>
        <div className="col-span-2 w-full">Move Back</div>
        <div className="col-span-2 w-full">Move Forward</div>
      </div>
      {purchaseOrders.map((purchaseOrder) => (
        <AdministratablePurchaseOrder
          key={purchaseOrder.id}
          purchaseOrder={purchaseOrder}
        />
      ))}
    </div>
  )
}


