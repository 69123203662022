import React from 'react'

const CTA = ({ id, submit, onclick, disabled, children }: { id:string, submit?:boolean, onclick?:Function, disabled?:boolean, children?:any }) => {

  return (
    <div className={`h-12 w-full border-b-4 border-primary rounded-full bg-light hover:bg-inherit`}>
        <button
          id={id}
          type={submit ? 'submit' : ''}
          onClick={onclick}
          className={`
            h-12 w-full flex items-center justify-center border-2 border-primary rounded-full bg-white text-dark text-xl font-light transform -translate-y-px transitional-all
            hover:transition-all hover:-translate-x-px hover:translate-y-px
          `}
          disabled={disabled || false}
        >
          { children }
        </button>
    </div>

  )
}

export default CTA
