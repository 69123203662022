import React from 'react'
import { Link } from 'react-router-dom'
import FormatInt from "../../utils/FormatInt";
import FormatDollars from "../../utils/FormatDollars";
import axios from 'axios'

const OrderProductRow = ({ orderProduct, orderId, state }) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  return (
    <React.Fragment>
      <Link to={`/all_products/${orderProduct.attributes.product?.brand_id}/${orderProduct.attributes.product?.id}`} className={"w-60"}>
        <p className={"font-reg text-sm text-dark mb-2"}>{orderProduct?.attributes.product.upc}</p>
        <div className={"flex"}>
          <img src={orderProduct?.attributes.images[0]} className={"h-16 w-16"} />
          <p className={"font-reg text-lg text-dark ml-2"}>{orderProduct?.attributes.product.name}</p>
        </div>
      </Link>
      <div className={"w-7/12 grid grid-cols-11"}>
        <div className={`text-dark font-light flex flex-col justify-end items-center col-span-3 text-center`}>
          <p className={`text-base my-2`}>{ FormatInt(orderProduct?.attributes.quantity) }</p>
          <p className={`text-xs`}>units</p>
        </div>
        <p className={"text-sm font-dark font-light grid justify-center items-end mb-6"}>X</p>
        <div className={`text-dark font-light flex flex-col justify-end items-center col-span-3 text-center`}>
          <p className={`text-base my-2`}>{ FormatDollars(orderProduct?.attributes.unit_cents) }</p>
          <p className={`text-xs`}>per unit</p>
        </div>
        <p className={"text-sm font-dark font-light grid justify-center items-end mb-6"}>=</p>
        <div className={'text-dark font-light flex flex-col justify-end items-center col-span-3 text-center'}>
          <p className={'text-base my-2'}>{FormatDollars(orderProduct.attributes.total_cents)}</p>
          <p className={'text-xs'}>total</p>
        </div>

        <div className={"flex flex-col justify-between items-end"}>
        </div>
      </div>
    </React.Fragment>
  )
}

export default OrderProductRow
