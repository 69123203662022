export default function emojiButtons(user) {

  let buttons =
    [
    {
      text: 'All Products', tag: 'All Products'
    },
    {
      text: 'Apparel',
      tag: 'Apparel'
    },
    {
      text: 'Beauty',
      tag: 'Beauty'
    },
    {
      text: 'Consumables',
      tag: 'Consumables'
    },
    {
      text: 'Footwear',
      tag: 'Footwear'
    },
    {
      text: 'Home',
      tag: 'Home'
    },
    {
      text: 'Pet',
      tag: 'Pet'
    },
    {
      text: 'Toys',
      tag: 'Toys'
    },
  ]

  return buttons
}
