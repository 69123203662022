import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import TextInput from "../../molecules/inputs/TextInput.tsx"
import CtaButton from '../../atoms/buttons/CtaButton.tsx'
import Overline from "~/components/atoms/text/Overline";
import Header1 from "~/components/atoms/text/Header1";
import Paragraph1 from "~/components/atoms/text/Paragraph1";
import SelectInput from "../molecules/SelectInput.tsx"
import { brandCategories } from '../../../utils/FormSelectOptions.jsx'
import AlreadyHaveAnAccount from '../../molecules/AlreadyHaveAnAccount.jsx'

const RetailerInquiryForm = () => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  let navigate = useNavigate()
  let [selectedBuyerCategories, setSelectedBuyerCategories] = useState([])
  let [error, setError] = useState("")
  const createRetailerProfile = (values, setSubmitting) => {
    axios.post("/profiles", values).then(
      (r) => {
        setSubmitting(false)
        navigate('/retailer_inquiry/submitted')
      }
    ).catch((err) => {
      if (err.response.status == 422) {
        setError(err.response.data.errors[0])
      }
    })
  }

  return (
    <section className='w-full sm:w-[496px] mx-4'>
      <header className={'mt-12 flex flex-col items-center'}>
        <Overline>Buyers</Overline>
        <Header1 styles={'text-center'}>Complete your application below</Header1>
        <Paragraph1 styles={'text-center'}>Apply to become a buyer to discover thousands of products up to 95% off MSRP</Paragraph1>
      </header>
      <Formik
        initialValues={{
          full_name: '',
          email: '',
          buyer_category: 'Toys',
          retailer_name: '',
        }}
        validationSchema={Yup.object({
          full_name: Yup.string()
            .required('Required'),
          email: Yup.string()
            .email('Please enter a valid email address')
            .required('Required'),
          retailer_name: Yup.string()
            .required('Required'),
          buyer_category: Yup.string()
            .required('Required')
            .oneOf(brandCategories, 'Invalid category')
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            createRetailerProfile({profile: values}, setSubmitting)
          }, 400);
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <TextInput
              label="Your Name*"
              name="full_name"
              type="text"
              placeholder=""
            />

            <TextInput
              label="Work Email Address*"
              name="email"
              type="email"
              placeholder=""
              onChange={(e) => {
                setFieldValue('email', e.target.value)
                setError("")
              }}
            />

            <TextInput
              label="Company Name*"
              name="retailer_name"
              type="text"
              placeholder=""
            />

            <SelectInput label="Buyer Category*" name="buyer_category" selectOptions={brandCategories} id={'retailer-inquiry-category-button'} />
            <div className={'h-10 w-full grid place-content-center'}>
              { error && <p className={"text-red-600 text-base font-med"}>{error}</p>}
            </div>
            <div className={'w-full'}>
              <CtaButton id={'retailer-inquiry-submit-button'} submit={true}>Submit</CtaButton>
            </div>
          </Form>
        )}
      </Formik>
      <AlreadyHaveAnAccount />
    </section>
  )
}

export default RetailerInquiryForm
