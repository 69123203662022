import FormatDollars from "~/utils/FormatDollars";

export const parseOffers = (offers) => {
  let parsedOffers = []
  let offer_products = offers.included

  offers.data.forEach((o) => {
    let obj = o.attributes
    obj.offer_products = o.relationships.offer_products.data.map((elem) => {
      return offer_products.find(op => op.id === elem.id)
    })
    parsedOffers.push(obj)
  })

  return parsedOffers.sort((a, b) => {
    return a.created_at - b.created_at
  })
}

export const getCartSkuCount = (offers) => {
  let count = 0
  offers.forEach((o) => {
    if (o.state == 'draft') {
      count += o.offer_products.length
    }
  })
  return count
}

export const getOfferByProduct = (offers, product) => {
  // return the offer that has the product as an offer product
  // offers (arr) offer (obj) .offer_products (arr) offer_product (obj) .attributes (obj) .product (obj) .id

  let list = offers.map((o) => {
    return {
      id: o.id,
      productIds: o.offer_products.map((op) => op.attributes.product.id)
    }
  })

  let offerId = list.find((el) => el.productIds.includes(product.id))?.id

  return offers.find((o) => o.id === offerId) || null
}

export const getDraftOffers = (offers) => {
  return offers.filter((o) => o.state === 'draft')
}
export const getSavedOffers = (offers) => {
  return offers.filter((o) => o.state === 'saved_for_later')
}

export const getOfferTotal = (offer) => {
  let total = 0
  offer.offer_products.forEach((op) => {
    total += op.attributes.total_price
  })
  return FormatDollars(total)
}
