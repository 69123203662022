import React, { useContext } from 'react'
import CtaLink from '../atoms/buttons/CtaLink.tsx'
import { CurrentUserContext } from "~/contexts/CurrentUser"
import Searchbar from '../organisms/headers/Searchbar.tsx'

const Banner = () => {

  let { currentUser } = useContext(CurrentUserContext)

  return (
    <div className={"w-full bg-light "}>
      <section className={"w-[1024px] mx-auto flex"}>
        <div className={"w-full px-12 flex flex-col items-center"}>
          <h1 className={"mt-6 font-thin text-7xl text-dark"}>
            Resale, simplified
          </h1>
          <h2 className={"mt-2 font-extralight text-3xl text-dark text-center"}>
            We are a private marketplace empowering the world's best brands to easily sell their inventory to a new group of buyers.
          </h2>
          {
            currentUser?.retailer ?
              <div className={'w-full mt-6'}>
                <Searchbar />
              </div>
              :
              <div className={'w-4/5 mt-8 mb-12 flex'}>
                <div className={'w-1/2 pr-4'}>
                  <CtaLink id={"banner-retailer-sign-up-link"} link={"/retailer_inquiry"}>Buy</CtaLink>
                  <p className={"mt-1 text-center font-light text-lg"}>Get access to the best brands at best prices</p>
                </div>
                <div className={'w-1/2 pl-4'}>
                  <CtaLink id={"banner-brand-sign-up-link"} link={"/brand_inquiry"}>Sell</CtaLink>
                  <p className={"mt-1 text-center font-light text-lg"}>Get cash for surplus stock</p>
                </div >
              </div>
          }
        </div>
      </section>
    </div>
  )
}

export default Banner
