import React, { useState, useEffect, useContext } from 'react'
import { useLocation, Link, useParams } from 'react-router-dom'
import axios from 'axios'
import Header1 from "../../components/atoms/text/Header1.tsx"
import Paragraph1 from "../../components/atoms/text/Paragraph1.tsx"
import Header2 from "../../components/atoms/text/Header2.tsx"
import TikTok from '../../entrypoints/images/tiktok-icon.svg'
import Insta from '../../entrypoints/images/insta-icon.svg'
import ProductCard from "~/components/molecules/ProductCard";
import ProductCard from "../../components/molecules/ProductCard.tsx";
import RequestForSample from '../molecules/RequestForSample.tsx'
import { CurrentUserContext } from '../../contexts/CurrentUser.js'

const BrandStore = () => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const params = useParams()
  let [brand, setBrand] = useState()
  let [products, setProducts] = useState([])
  let [currentTag, setCurrentTag] = useState('All Products')
  let [retailersOpen, setRetailersOpen] = useState(false)

  const { currentUser } = useContext(CurrentUserContext)

  const filterButtons = [
    {
      text: '🛍️ All Products', tag: 'All Products'
    }, {
      text: '🦄 Top Sellers', tag: 'Top Sellers'
    },
    {
      text: '😜 Novelty',
      tag: 'Novelty'
    },
    {
      text: '💪🏻 Consumables',
      tag: 'Consumables'
    },
    {text: '💵 Under $10', tag: 'Under $10'},
    {text: '🤳 1M+ Followers', tag: '1M+ Followers'},
    {text: '🚀 $5M+ Sales', tag: '$5M+ Sales'},
    {
      text: '🧸 Toys & Games',
      tag: 'Toys'
    },
    {
      text: '🌊 Wellness',
      tag: 'Wellness'
    },
    {
      text: '🧴 Skin & Beauty',
      tag: 'Beauty'
    },
    {
      text: '🏠 Home',
      tag: 'Home'
    },
    {
      text: '🍼 Baby',
      tag: 'Baby'
    },
    {
      text: '🐶 Pets',
      tag: 'Pet'
    },
    {
      text: '🔨 Tools',
      tag: 'Tools'
    },
    { text: '⚡️ Electronics', tag: 'Electronics' },
    {
      text: '🖊️ Stationery', tag: 'Stationery'
    },
    {
      text: '🏋️ Exercise',
      tag: 'Exercise'
    },

  ]

  const makeProductFilters = () => {
    let styles = 'font-reg text-base my-2'
    let active = 'text-almost-black'
    let inactive = 'text-medium-gray'

    let pfs = filterButtons.map((f) => {
      return (
        <button onClick={() => setCurrentTag(f.tag)} className={currentTag === f.tag ? active + " " + styles : inactive + " " + styles}>
          {f.text}
        </button>
      )
    })

    return pfs
  }

  let [page, setPage] = useState(1)
  let [sort, setSort] = useState('newest')
  let [productCount, setProductCount] = useState(0)

  useEffect(() => {
    let loadingProducts = true

    const loadTagged = async (tag) => {
      axios.post(`/marketplace/products/search?page=${page}&sort=${sort}`, { search: { tags: [tag], brand_ids: [params.brand_id]}}).then((pr) => {
        setProductCount(pr.data.count)
        if (loadingProducts) {
          if (page === 1) {
            setProducts(pr.data.products)
          } else if (page < pr.data.total_pages + 1) {
            page = page + 1
            loadTagged(tag)
            setProducts(products => [...products, ...pr.data.products])
          }
        }
      })
    }

    if (currentTag !== 'All Products') {
      loadTagged(currentTag)
    } else {
      getAllProducts()
    }
    return () => { loadingProducts = false }
  }, [currentTag])

  const getAllProducts = () => {
    axios.get(`/marketplace/brands/${params.brand_id}`).then((r) => {
      setBrand(r.data)
      let prods = r.data.published_products.map((p) => {
        p.brand = r.data
        return p
      })
      if (!currentUser.email) {
        setProducts(prods)
        return
      }
      axios.get('/favorites').then((fr) => {
        let f = fr.data.products
        if (currentUser && !!!currentUser.brand?.id) {
          prods = prods.map((prod) => {
            prod.favorited = f.map((fav) => fav.id).includes(prod.id)
            return prod
          })
        }
        return prods
      }).then((favoritedProducts) => {
        setProducts(favoritedProducts)
      })
    })
  }

  useEffect(() => {
    getAllProducts()
    makeProductFilters()
  }, [])

  return (
    <section className={'h-full w-[1024px] bg-none mt-8'}>
      <header className={'flex text-almost-black text-sm font-reg'}>
        <Link to={'/marketplace'} className={'hover:underline'}>Home</Link>
        <p className={'mx-2'}>/</p>
        <p>{brand?.name}</p>
      </header>
      <section className={'pb-6 border-b border-white mt-4'}>
        {
          brand?.banner_url && <img src={brand?.banner_url} alt={'brand banner'} className={'h-[300px] w-[1165px] bg-white border-x border-gray'}/>
        }
        <div className={'h-52 w-full flex mt-2'}>
          <div className={'h-full w-full flex'}>
            <img src={brand?.logo_url} alt={'brand logo'} className={'h-52 w-52 shrink-0 bg-white border border-gray'}/>
            <section className={'w-full bg-white border border-gray mx-2 p-3 overflow-hidden'}>
              <div className={'w-full flex justify-between'}>
                <Header1>{brand?.name}</Header1>
              </div>
              <Paragraph1 styles={'mt-2'}>{brand?.description}</Paragraph1>
            </section>
          </div>
        </div>
      </section>

      <section className={'my-6 flex'}>
        {/*<aside className={'w-1/5 flex flex-col items-start'}>*/}
        {/*  { makeProductFilters() }*/}
        {/*</aside>*/}
        <section className={'w-full'}>
          <div className={'grid grid-cols-2'}>
            <div>
              <Header1 styles={'mb-6'}>{`All ${brand?.name} products`}</Header1>
            </div>
          </div>
          <div className={'w-full grid grid-cols-5 gap-x-4 gap-y-4'}>
            {
              products?.map((p) => {

                return <ProductCard product={p} />
              })
            }
          </div>
        </section>
      </section>
    </section>
  )
}

export default BrandStore
