import React, { useContext, useEffect, useRef } from 'react'
import Banner from '../organisms/Banner.tsx'
import axios from 'axios'
import { CurrentUserContext } from "../../contexts/CurrentUser.js"

const Marketplace = () => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const { currentUser } = useContext(CurrentUserContext)
  const buyerTop = "https://ik.imagekit.io/mmbi/man_on_desktop_comp.png?updatedAt=1736515098776?tr=w-1600,h-1600,fo-custom,cm-extract"
  const buyerSection = "https://ik.imagekit.io/mmbi/three_ppl_at_laptop.png?updatedAt=1736515098733?tr=w-1602,h-1602,fo-custom,cm-extract"
  const sellerTop = "https://ik.imagekit.io/mmbi/two_ppl_packaging.png?updatedAt=1736515099114?tr=w-800,h-800"
  const sellerSection = "https://ik.imagekit.io/mmbi/two_ppl_with_boxes_from_above.png?updatedAt=1736515098633?tr=w-1600,h-1600,fo-custom,cm-extract"

  return (
    <div className={"w-full bg-light"}>
      <Banner />
      <section className={"w-full bg-light mb-12 flex flex-col items-center"}>
        <div className={"w-[1024px] grid grid-cols-2"}>
          <div className={`h-[32rem] mx-6 bg-cover bg-center rounded-xl`} style={{ backgroundImage: `url(${buyerTop})`}}>
            <h2 className={'pt-2 font-med text-3xl text-dark bg-light bg-opacity-60 text-center rounded-t-xl'}>Large Retail Buyers</h2>
            <p className={'pb-1 font-extralight text-lg text-dark bg-light bg-opacity-60 text-center'}>Large buyers purchase inventory for resale, with tools and support to grow their business and maximize margins.</p>
          </div>
          <div className={`h-[32rem] mx-6 bg-cover bg-center rounded-xl`} style={{ backgroundImage: `url(${sellerTop})`}}>
            <h2 className={'pt-2 font-med text-3xl text-light bg-dark bg-opacity-60 text-center rounded-t-xl'}>Emerging Ecommerce Brands</h2>
            <p className={'pb-1 font-extralight text-lg text-light bg-dark bg-opacity-60 text-center'}>Brands submit inventory to capture maximum recovery while keeping brand protection.</p>
          </div>
        </div>
      </section>
      <section className={"w-full bg-primary h-96 flex flex-col justify-center items-center"}>
        <div className={"w-[1024px]"}>
          <h2 className={"font-light text-5xl text-center text-light"}>We are backed by the largest ecommerce financing business</h2>
          <div className={"grid grid-cols-3"}>
            <div className={"text-light flex flex-col items-center"}>
              <h2 className={"font-bold text-8xl"}>6k+</h2>
              <p className={"font-extralight text-2xl"}>ecommerce brands</p>
            </div>
            <div className={"text-light flex flex-col items-center"}>
              <h2 className={"font-bold text-8xl"}>$1b+</h2>
              <p className={"font-extralight text-2xl"}>in financing capital</p>
            </div>
            <div className={"text-light flex flex-col items-center"}>
              <h2 className={"font-bold text-8xl"}>100k+</h2>
              <p className={"font-extralight text-2xl"}>posted listings</p>
            </div>
          </div>
        </div>
      </section>
      <section className={"w-full bg-light flex flex-col items-center py-8"}>
        <h2 className={"font-med text-3xl text-dark text-center uppercase"}>Buyers</h2>
        <h2 className={"font-reg text-3xl text-dark mt-2 mb-6"}>Acquire the world’s best products</h2>
        <div className={"w-[1024px] grid grid-cols-2"}>
          <div className={"flex flex-col justify-around mx-4"}>
            <h3 className={"font-extralight text-2xl text-dark"}>Products from the top emerging brands</h3>
            <h3 className={"font-extralight text-2xl text-dark"}>Use our platform to grow your business</h3>
            <h3 className={"font-extralight text-2xl text-dark"}>Experience our curated marketplace</h3>
          </div>
          <img src={buyerSection} className={"rounded-xl"} />
        </div>
      </section>
      <section className={"w-full bg-secondary flex flex-col items-center py-8"}>
        <h2 className={"font-med text-3xl text-light text-center uppercase"}>Sellers</h2>
        <h2 className={"font-reg text-3xl text-light mt-2 mb-6"}>Get cash for all types of inventory</h2>
        <div className={"w-[1024px] grid grid-cols-2"}>
          <img src={sellerSection} className={"rounded-xl"} />
          <div className={"flex flex-col justify-around mx-4"}>
            <h3 className={"font-extralight text-2xl text-light"}>Quickly sell your inventory for cash</h3>
            <h3 className={"font-extralight text-2xl text-light"}>Set your offer price for your products</h3>
            <h3 className={"font-extralight text-2xl text-light"}> Our private marketplace is safe, secure and backed by the larges ecommerce financing business in the world</h3>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Marketplace
