import React from 'react'
export const brandCategories = [
  'Apparel',
  'Beauty',
  'Consumables',
  'Footwear',
  'Home',
  'Pet',
  'Toys'
]

export const salesNumbers = [
  '<$1M',
  '$1M-$5M',
  '>$5M',
  'Prefer not to disclose',
]

export const followerCounts = [
  '<100K',
  '100K+',
  '500K+',
  '1M+',
]

export const makeOption = (item) => {
  return (
    <option value={item}>{item}</option>
  )
}

export const productCategories = [
  'Other Category',
  'Apparel',
  'Beauty',
  'Consumables',
  'Footwear',
  'Home',
  'Pet',
  'Toys'
]

export const productGenders = [
  'F',
  'M',
  'Unisex'
]

export const packagingTypes = [
  'Other packaging type',
  'Retail ready',
  'Ecommerce ready'
]

export const stateCodes = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL',
  'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME',
  'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH',
  'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI',
  'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI',
  'WY'
]

export const percents = [
  'w-[0%]',
  'w-[1%]',
  'w-[2%]',
  'w-[3%]',
  'w-[4%]',
  'w-[5%]',
  'w-[6%]',
  'w-[7%]',
  'w-[8%]',
  'w-[9%]',
  'w-[10%]',
  'w-[11%]',
  'w-[12%]',
  'w-[13%]',
  'w-[14%]',
  'w-[15%]',
  'w-[16%]',
  'w-[17%]',
  'w-[18%]',
  'w-[19%]',
  'w-[20%]',
  'w-[21%]',
  'w-[22%]',
  'w-[23%]',
  'w-[24%]',
  'w-[25%]',
  'w-[26%]',
  'w-[27%]',
  'w-[28%]',
  'w-[29%]',
  'w-[30%]',
  'w-[31%]',
  'w-[32%]',
  'w-[33%]',
  'w-[34%]',
  'w-[35%]',
  'w-[36%]',
  'w-[37%]',
  'w-[38%]',
  'w-[39%]',
  'w-[40%]',
  'w-[41%]',
  'w-[42%]',
  'w-[43%]',
  'w-[44%]',
  'w-[45%]',
  'w-[46%]',
  'w-[47%]',
  'w-[48%]',
  'w-[49%]',
  'w-[50%]',
  'w-[51%]',
  'w-[52%]',
  'w-[53%]',
  'w-[54%]',
  'w-[55%]',
  'w-[56%]',
  'w-[57%]',
  'w-[58%]',
  'w-[59%]',
  'w-[60%]',
  'w-[61%]',
  'w-[62%]',
  'w-[63%]',
  'w-[64%]',
  'w-[65%]',
  'w-[66%]',
  'w-[67%]',
  'w-[68%]',
  'w-[69%]',
  'w-[70%]',
  'w-[71%]',
  'w-[72%]',
  'w-[73%]',
  'w-[74%]',
  'w-[75%]',
  'w-[76%]',
  'w-[77%]',
  'w-[78%]',
  'w-[79%]',
  'w-[80%]',
  'w-[81%]',
  'w-[82%]',
  'w-[83%]',
  'w-[84%]',
  'w-[85%]',
  'w-[86%]',
  'w-[87%]',
  'w-[88%]',
  'w-[89%]',
  'w-[90%]',
  'w-[91%]',
  'w-[92%]',
  'w-[93%]',
  'w-[94%]',
  'w-[95%]',
  'w-[96%]',
  'w-[97%]',
  'w-[98%]',
  'w-[99%]',
  'w-[100%]',
]
